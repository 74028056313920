// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../public/favicon.ico", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../public/logo128.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../public/manifest.json", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<!DOCTYPE html> <html lang=\"en\"> <head> <meta charset=\"utf-8\"/> <link rel=\"icon\" href=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\"/> <meta name=\"theme-color\" content=\"#000000\"/> <meta name=\"description\" content=\"Medical Insights Hub\"/> <link rel=\"apple-touch-icon\" href=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> <link rel=\"manifest\" href=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"/> <title>Medical Insights Hub</title> </head> <body> <noscript>You need to enable JavaScript to run this app.</noscript> <div id=\"root\"></div> </body> </html> ";
// Exports
export default code;