/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Link,
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { getStore, RootState } from '@ink-ai/portal/reducers';
import LoadingButton from '@mui/lab/LoadingButton';
import { downloadFile } from '@ink-ai/portal/common/utils';
import { StiType } from '@ink-ai/insight-service-sdk';
import { useNavigationListener } from '../../components/hooks/useNavigationListener';
import { referenceStoreCSVTemplate } from '../../components/reference-store/Template';
import {
  FileUploadStatus,
  importReferenceStore,
  referenceStoreActions,
} from '@ink-ai/portal/reducers/reference-store';
import {
  FileUpload,
  FileUploadRef,
} from '../../components/file-upload/FileUpload';
import { app } from '@ink-ai/portal/reducers/app';

const downloadCSVTemplate = () => {
  downloadFile(
    `\uFEFF${referenceStoreCSVTemplate}`, // Using BOM to force Excel to open CSV in UTF-8
    'referenceStore-template.csv',
    'text/csv',
  );
};

interface ImportReferenceStoreProps {
  referenceStoreUuid?: string;
  referenceStoreName?: string;
  referenceStoreDescription?: string;
}

const confirmExit = () => {
  const referenceStore = getStore().getState().referenceStore;
  if (referenceStore.FileUploadStatus === FileUploadStatus.Uploading) {
    return 'The document is still in the process of uploading. Are you sure, you want to close?';
  }
};

export const ImportReferenceStore: React.FC<ImportReferenceStoreProps> = () => {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const { referenceStoreUuid, referenceStoreName, referenceStoreDescription } =
    location.state || {};
  const navigate = useNavigate();
  const referenceStore = useSelector(
    (state: RootState) => state.referenceStore,
  );
  const [name, setName] = useState(referenceStoreName);
  const [description, setDescription] = useState(referenceStoreDescription);
  const uploadRef = useRef<FileUploadRef>(null);

  useNavigationListener();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      referenceStore.FileUploadStatus === FileUploadStatus.Uploading &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleCancel = () => {
    navigate('/reference-store');
  };

  useEffect(() => {
    // Clean up on unmount
    return () => {
      dispatch(referenceStoreActions.clearAll());
    };
  }, []);

  const handleUploadComplete = useCallback(
    (resourceId: string) => {
      dispatch(
        referenceStoreActions.setFileUploadStatus({
          status: FileUploadStatus.Complete,
        }),
      );
      if (referenceStoreUuid) {
        dispatch(
          importReferenceStore({
            uuid: referenceStoreUuid,
            name,
            description,
            uploadTaskId: resourceId,
          }),
        );
      } else {
        dispatch(
          importReferenceStore({
            name,
            description,
            uploadTaskId: resourceId,
          }),
        );
      }

      dispatch(
        app.actions.setGlobalMessage({
          message: `Reference store ${
            referenceStoreUuid ? 'updated' : 'created'
          } successfully.`,
          status: 'success',
        }),
      );
    },
    [dispatch, name, description, referenceStoreUuid],
  );

  const handleUploadError = useCallback(
    (error: Error) => {
      dispatch(
        referenceStoreActions.setFileUploadStatus({
          status: FileUploadStatus.Error,
        }),
      );

      dispatch(
        app.actions.setGlobalMessage({
          message: error.message || 'Failed to upload reference store file.',
          status: 'error',
        }),
      );

      dispatch(referenceStoreActions.setLoading(false));
    },
    [dispatch],
  );

  const handleStatusChange = useCallback(
    (status: FileUploadStatus) => {
      dispatch(
        referenceStoreActions.setFileUploadStatus({
          status,
        }),
      );
    },
    [dispatch],
  );

  const handleFileRemove = useCallback(() => {
    dispatch(
      referenceStoreActions.setFileUploadStatus({
        status: FileUploadStatus.WaitingUpload,
      }),
    );
  }, [dispatch]);

  const handleStartFileUpload = () => {
    dispatch(
      referenceStoreActions.setFileUploadStatus({
        status: FileUploadStatus.Uploading,
      }),
    );
    uploadRef.current?.startFileUpload();
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          color="inherit"
          onClick={() => navigate('/reference-store')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          Reference Store
        </Link>
        <Typography color="textPrimary">Import</Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ textAlign: 'left' }}>
          {referenceStoreUuid ? 'Update' : 'Import'} Reference Store
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mb: 3, textAlign: 'left' }}
        >
          {referenceStoreUuid
            ? 'Updating an existing reference store by importing from a file.'
            : 'Creating a reference store by importing from a file.'}
        </Typography>
        <Box sx={{ width: '50%' }}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={name}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Description (Optional)"
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            value={description}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Typography variant="body2" sx={{ mb: 1, textAlign: 'left' }}>
            Download the{' '}
            <Link href="#" onClick={downloadCSVTemplate}>
              CSV template
            </Link>
            , and import.
          </Typography>
          <FileUpload
            ref={uploadRef}
            accept=".csv"
            ownerType={StiType.ReferenceStore}
            onStatusChange={handleStatusChange}
            onUploadComplete={handleUploadComplete}
            onFileRemove={handleFileRemove}
            onUploadError={handleUploadError}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-start',
          }}
        >
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleStartFileUpload}
            disabled={!name || referenceStore.loading}
            sx={{ width: '99px' }}
            loading={
              referenceStore.loading ||
              [FileUploadStatus.Uploading, FileUploadStatus.Importing].includes(
                referenceStore.FileUploadStatus,
              )
            }
          >
            {referenceStoreUuid ? 'Update' : 'Import'}
          </LoadingButton>
          <Button onClick={handleCancel} sx={{ ml: 1, width: '99px' }}>
            Cancel
          </Button>
        </Box>
      </Paper>

      <Dialog open={blocker.state === 'blocked'}>
        <DialogTitle>Uploading Document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The document is currently being uploaded. Please refrain from
            navigating away from this page until the upload process is fully
            completed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => blocker.proceed()}>Leave anyway</Button>
          <Button
            onClick={() => blocker.reset()}
            color="primary"
            variant="contained"
            autoFocus
          >
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
