'use strict';
var validate = (function() {
  var refVal = [];
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    'use strict';
    var vErrors = null;
    var errors = 0;
    if (rootData === undefined) rootData = data;
    if (Array.isArray(data)) {
      var errs__0 = errors;
      var valid0;
      for (var i0 = 0; i0 < data.length; i0++) {
        var data1 = data[i0];
        var errs_1 = errors;
        if ((data1 && typeof data1 === "object" && !Array.isArray(data1))) {
          if (true) {
            var errs__1 = errors;
            var valid2 = true;
            if (data1.name === undefined) {
              valid2 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + '[' + i0 + ']',
                schemaPath: '#/items/required',
                params: {
                  missingProperty: 'name'
                },
                message: 'should have required property \'name\''
              }];
              return false;
            } else {
              var errs_2 = errors;
              if (typeof data1.name !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '[' + i0 + '].name',
                  schemaPath: '#/items/properties/name/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid2 = errors === errs_2;
            }
            if (valid2) {
              var data2 = data1.desc;
              if (data2 === undefined) {
                valid2 = true;
              } else {
                var errs_2 = errors;
                if (typeof data2 !== "string" && data2 !== null) {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '[' + i0 + '].desc',
                    schemaPath: '#/items/properties/desc/type',
                    params: {
                      type: 'string,null'
                    },
                    message: 'should be string,null'
                  }];
                  return false;
                }
                var valid2 = errors === errs_2;
              }
              if (valid2) {
                var data2 = data1.abbr;
                if (data2 === undefined) {
                  valid2 = true;
                } else {
                  var errs_2 = errors;
                  if (typeof data2 !== "string" && data2 !== null) {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '[' + i0 + '].abbr',
                      schemaPath: '#/items/properties/abbr/type',
                      params: {
                        type: 'string,null'
                      },
                      message: 'should be string,null'
                    }];
                    return false;
                  }
                  var valid2 = errors === errs_2;
                }
                if (valid2) {
                  var data2 = data1.translations;
                  if (data2 === undefined) {
                    valid2 = false;
                    validate.errors = [{
                      keyword: 'required',
                      dataPath: (dataPath || '') + '[' + i0 + ']',
                      schemaPath: '#/items/required',
                      params: {
                        missingProperty: 'translations'
                      },
                      message: 'should have required property \'translations\''
                    }];
                    return false;
                  } else {
                    var errs_2 = errors;
                    if (Array.isArray(data2)) {
                      var errs__2 = errors;
                      var valid2;
                      for (var i2 = 0; i2 < data2.length; i2++) {
                        var data3 = data2[i2];
                        var errs_3 = errors;
                        if ((data3 && typeof data3 === "object" && !Array.isArray(data3))) {
                          if (true) {
                            var errs__3 = errors;
                            var valid4 = true;
                            if (data3.language === undefined) {
                              valid4 = false;
                              validate.errors = [{
                                keyword: 'required',
                                dataPath: (dataPath || '') + '[' + i0 + '].translations[' + i2 + ']',
                                schemaPath: '#/items/properties/translations/items/required',
                                params: {
                                  missingProperty: 'language'
                                },
                                message: 'should have required property \'language\''
                              }];
                              return false;
                            } else {
                              var errs_4 = errors;
                              if (typeof data3.language !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '[' + i0 + '].translations[' + i2 + '].language',
                                  schemaPath: '#/items/properties/translations/items/properties/language/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid4 = errors === errs_4;
                            }
                            if (valid4) {
                              if (data3.translation === undefined) {
                                valid4 = false;
                                validate.errors = [{
                                  keyword: 'required',
                                  dataPath: (dataPath || '') + '[' + i0 + '].translations[' + i2 + ']',
                                  schemaPath: '#/items/properties/translations/items/required',
                                  params: {
                                    missingProperty: 'translation'
                                  },
                                  message: 'should have required property \'translation\''
                                }];
                                return false;
                              } else {
                                var errs_4 = errors;
                                if (typeof data3.translation !== "string") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '[' + i0 + '].translations[' + i2 + '].translation',
                                    schemaPath: '#/items/properties/translations/items/properties/translation/type',
                                    params: {
                                      type: 'string'
                                    },
                                    message: 'should be string'
                                  }];
                                  return false;
                                }
                                var valid4 = errors === errs_4;
                              }
                              if (valid4) {}
                            }
                            if (errs__3 == errors) {}
                          }
                        } else {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '[' + i0 + '].translations[' + i2 + ']',
                            schemaPath: '#/items/properties/translations/items/type',
                            params: {
                              type: 'object'
                            },
                            message: 'should be object'
                          }];
                          return false;
                        }
                        if (errors === errs_3) {}
                        var valid3 = errors === errs_3;
                        if (!valid3) break;
                      }
                      if (errs__2 == errors) {}
                    } else {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '[' + i0 + '].translations',
                        schemaPath: '#/items/properties/translations/type',
                        params: {
                          type: 'array'
                        },
                        message: 'should be array'
                      }];
                      return false;
                    }
                    if (errors === errs_2) {}
                    var valid2 = errors === errs_2;
                  }
                  if (valid2) {}
                }
              }
            }
            if (errs__1 == errors) {}
          }
        } else {
          validate.errors = [{
            keyword: 'type',
            dataPath: (dataPath || '') + '[' + i0 + ']',
            schemaPath: '#/items/type',
            params: {
              type: 'object'
            },
            message: 'should be object'
          }];
          return false;
        }
        if (errors === errs_1) {}
        var valid1 = errors === errs_1;
        if (!valid1) break;
      }
      if (errs__0 == errors) {}
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'array'
        },
        message: 'should be array'
      }];
      return false;
    }
    if (errors === 0) {}
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "array",
  "items": {
    "type": "object",
    "properties": {
      "name": {
        "type": "string"
      },
      "desc": {
        "type": ["string", "null"]
      },
      "abbr": {
        "type": ["string", "null"]
      },
      "translations": {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "language": {
              "type": "string"
            },
            "translation": {
              "type": "string"
            }
          },
          "required": ["language", "translation"]
        }
      }
    },
    "required": ["name", "translations"]
  }
};
validate.errors = null;
module.exports = validate;