/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosProgressEvent } from 'axios';
import { getApi } from '../common/requestHelper';
import {
  DocumentTranslationApi,
  DocumentTranslationOptionDtoTargetLanguageEnum,
  DocumentTranslationResponseItemDtoStatusEnum,
  GetDocumentTranslationResponseDto,
} from '@ink-ai/insight-service-sdk';
import { getStore, RootState } from '.';

export const languageOptions = new Map<
  DocumentTranslationOptionDtoTargetLanguageEnum,
  string
>([
  [DocumentTranslationOptionDtoTargetLanguageEnum.ZhCn, 'Chinese (Simplified)'],
  [DocumentTranslationOptionDtoTargetLanguageEnum.EnUs, 'English'],
  [DocumentTranslationOptionDtoTargetLanguageEnum.Ja, 'Japanese'],
]);

export const statusStringMapping: Record<
  DocumentTranslationResponseItemDtoStatusEnum,
  string
> = {
  [DocumentTranslationResponseItemDtoStatusEnum.PendingUpload]:
    'Pending Upload',
  [DocumentTranslationResponseItemDtoStatusEnum.Translating]: 'Translating',
  [DocumentTranslationResponseItemDtoStatusEnum.Success]: 'Succeeded',
  [DocumentTranslationResponseItemDtoStatusEnum.Error]: 'Failed',
};

export const statusClassMapping: Record<
  DocumentTranslationResponseItemDtoStatusEnum,
  string
> = {
  [DocumentTranslationResponseItemDtoStatusEnum.PendingUpload]: 'text-blue-600',
  [DocumentTranslationResponseItemDtoStatusEnum.Translating]: 'text-blue-600',
  [DocumentTranslationResponseItemDtoStatusEnum.Success]: 'text-green-600',
  [DocumentTranslationResponseItemDtoStatusEnum.Error]: 'text-red-600',
};

export interface TranslationState {
  mode: 'Text' | 'Documents';
  sourceLanguages: DocumentTranslationOptionDtoTargetLanguageEnum[];
  targetLanguages: DocumentTranslationOptionDtoTargetLanguageEnum[];
  sourceLanguage: DocumentTranslationOptionDtoTargetLanguageEnum;
  targetLanguage: DocumentTranslationOptionDtoTargetLanguageEnum;
  glossaryDialogOpen: boolean;
  selectedGlossaries: string[];
  referenceStoreDialogOpen: boolean;
  selectedReferenceStores: string[];
  fileName: string;
  fileSize: number;
  translationStatus:
    | 'waiting upload'
    | 'uploading'
    | 'translating'
    | 'completed'
    | 'failed';
  progress: number;
  uuid: string | null;
  uploadUrl: string | null;
  instanceId: string | null;
  loading: boolean;
  errorMessage: string;
  selectedFile?: File;
  timestamp: number; // Used to tell the action whether the job is outdated
  sourceText?: string;
}

const initialState: TranslationState = {
  mode: 'Text',
  sourceLanguages: [
    DocumentTranslationOptionDtoTargetLanguageEnum.EnUs,
    DocumentTranslationOptionDtoTargetLanguageEnum.ZhCn,
  ], // languages that can be displayed on the language tab
  targetLanguages: [
    DocumentTranslationOptionDtoTargetLanguageEnum.ZhCn,
    DocumentTranslationOptionDtoTargetLanguageEnum.EnUs,
  ],
  sourceLanguage: DocumentTranslationOptionDtoTargetLanguageEnum.EnUs,
  targetLanguage: DocumentTranslationOptionDtoTargetLanguageEnum.ZhCn,
  glossaryDialogOpen: false,
  selectedGlossaries: [],
  referenceStoreDialogOpen: false,
  selectedReferenceStores: [],
  fileName: '',
  fileSize: 0,
  translationStatus: 'waiting upload',
  progress: 0,
  uuid: null,
  uploadUrl: null,
  instanceId: null,
  loading: false,
  errorMessage: '',
  timestamp: 0,
  sourceText: '',
};

export const handleDownload = async (id: string) => {
  const store = getStore();
  try {
    store.dispatch(translationActions.setLoading(true));
    const translateApi = await getApi(DocumentTranslationApi);
    const response = await translateApi.getTranslationDownloadLink(id);
    const a = document.createElement('a');
    a.href = response.data.downloadLink;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error: any) {
    console.error('Failed to download the file:', error);
  } finally {
    store.dispatch(translationActions.setLoading(false));
  }
};

export const uploadAndTranslate = createAsyncThunk(
  'translation/uploadAndTranslate',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const instanceId = state.auth.instanceId;
    const initTimestamp = state.translation.timestamp;
    dispatch(translationActions.setInstanceId(instanceId));

    // Get the presigned URL
    dispatch(translationActions.setTranslationStatus('uploading'));
    const translateApi = await getApi(DocumentTranslationApi);
    const response = await translateApi.createForUpload({
      filename: state.translation.fileName,
      instanceId: instanceId,
      option: {
        targetLanguage: state.translation.targetLanguage,
        sourceLanguage: state.translation.sourceLanguage,
      },
    });
    const { uuid, uploadUrl } = response.data;
    dispatch(translationActions.setUuid(uuid));

    // Upload the file to S3
    await axios.put(uploadUrl, state.translation.selectedFile, {
      headers: {
        'Content-Type':
          state.translation.selectedFile.type || 'application/octet-stream',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 1),
        );
        const currState = getState() as RootState;
        if (initTimestamp !== currState.translation.timestamp) {
          return;
        }
        dispatch(translationActions.setProgress(progress));
      },
    });

    // Start the translation
    const currState = getState() as RootState;
    if (initTimestamp !== currState.translation.timestamp) {
      console.warn('The job is outdated, aborting');
      return;
    }
    dispatch(translationActions.setTranslationStatus('translating'));
    await translateApi.start(uuid, {
      glossaries: state.translation.selectedGlossaries,
      referenceStores: state.translation.selectedReferenceStores,
    });
  },
);

export const refreshTranslationStatus = createAsyncThunk(
  'translation/refreshTranslationStatus',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const translateApi = await getApi(DocumentTranslationApi);
    const response = await translateApi.getDocumentTranslation(
      state.translation.uuid,
    );
    dispatch(translationActions.updateDocumentTranslationResult(response.data));
  },
);

export const translation = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    updateDocumentTranslationResult(
      state,
      action: PayloadAction<GetDocumentTranslationResponseDto>,
    ) {
      if (action.payload.uuid !== state.uuid) {
        console.log(
          'The translation job is outdated, aborting websocket message',
        );
        return;
      }
      if (action.payload.status === 'ERROR') {
        state.translationStatus = 'failed';
        state.loading = false;
        state.errorMessage = action.payload.errorMessage;
      } else if (action.payload.status === 'SUCCESS') {
        state.translationStatus = 'completed';
        state.loading = false;
      }
      state.errorMessage = action.payload.errorMessage;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setTranslationStatus(
      state,
      action: PayloadAction<TranslationState['translationStatus']>,
    ) {
      state.translationStatus = action.payload;
    },
    setMode(state, action: PayloadAction<'Text' | 'Documents'>) {
      state.mode = action.payload;
    },
    setSourceLanguage(
      state,
      action: PayloadAction<DocumentTranslationOptionDtoTargetLanguageEnum>,
    ) {
      state.sourceLanguage = action.payload;
      state.sourceLanguages = [
        action.payload,
        ...state.sourceLanguages.filter((lang) => lang !== action.payload),
      ].slice(0, 3); // This number controls the maximum number of languages that can be displayed on the language tab
    },
    setTargetLanguage(
      state,
      action: PayloadAction<DocumentTranslationOptionDtoTargetLanguageEnum>,
    ) {
      state.targetLanguage = action.payload;
      state.targetLanguages = [
        action.payload,
        ...state.targetLanguages.filter((lang) => lang !== action.payload),
      ].slice(0, 3);
    },
    setGlossaryDialogOpen(state, action: PayloadAction<boolean>) {
      state.glossaryDialogOpen = action.payload;
    },
    setSelectedGlossaries(state, action: PayloadAction<string[]>) {
      state.selectedGlossaries = action.payload;
    },
    setReferenceStoreDialogOpen(state, action: PayloadAction<boolean>) {
      state.referenceStoreDialogOpen = action.payload;
    },
    setSelectedReferenceStores(state, action: PayloadAction<string[]>) {
      state.selectedReferenceStores = action.payload;
    },
    setFileName(state, action: PayloadAction<string>) {
      state.fileName = action.payload;
    },
    setFileSize(state, action: PayloadAction<number>) {
      state.fileSize = action.payload;
    },
    setProgress(state, action: PayloadAction<number>) {
      state.progress = action.payload;
    },
    setInstanceId(state, action: PayloadAction<string>) {
      state.instanceId = action.payload;
    },
    clearAll: () => {
      return {
        ...initialState,
        timestamp: Date.now(),
      };
    },
    setSelectedFile(state, action: PayloadAction<File>) {
      state.selectedFile = action.payload;
    },
    setUuid(state, action: PayloadAction<string>) {
      state.uuid = action.payload;
    },
    setTimeStamp(state, action: PayloadAction<number | undefined>) {
      state.timestamp = action.payload ?? Date.now();
    },
    removeFile(state) {
      state.selectedFile = undefined;
      state.fileName = '';
      state.fileSize = 0;
      state.translationStatus = 'waiting upload';
      state.progress = 0;
      state.uuid = null;
      state.uploadUrl = null;
      state.timestamp = Date.now();
      state.loading = false;
    },
    setSourceText(state, action: PayloadAction<string>) {
      state.sourceText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadAndTranslate.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadAndTranslate.rejected, (state) => {
        state.loading = false;
        state.translationStatus = 'failed';
      });
  },
});

export const translationActions = translation.actions;
export default translation.reducer;
