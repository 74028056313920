/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WritingInstructionResponseItemDto } from '@ink-ai/insight-service-sdk';
import { Descendant } from 'slate';
import { WritingInstructionApi } from '@ink-ai/insight-service-sdk';
import { getApi } from '@ink-ai/portal/common/requestHelper';

export type WritingInstructionState = {
  data: WritingInstructionResponseItemDto;
  isEdit: boolean;
  userInstructionObj: Descendant[];
  loading?: boolean;
};

const INIT_USER_INSTRUCTION: Descendant[] = [
  {
    type: 'paragraph',
    children: [
      {
        text: '',
      },
    ],
  },
];

const initialState: WritingInstructionState = {
  isEdit: false,
  userInstructionObj: INIT_USER_INSTRUCTION,
  data: {
    id: '',
    title: '',
    description: '',
    userInstruction: '',
    detailInstruction: '',
    examples: [''],
    createdAt: 0,
    updatedAt: 0,
  },
};

const slateToString = (nodes: Descendant[]): string => {
  return nodes
    .map((node) => {
      if ('children' in node && Array.isArray(node.children)) {
        return node.children
          .map((child) => ('text' in child ? child.text : ''))
          .join('');
      }
      return '';
    })
    .join('\n');
};

const stringToSlate = (text: string): Descendant[] => {
  return text.split('\n').map((line) => ({
    type: 'paragraph',
    children: [{ text: line }],
  }));
};

// define Thunk to get options data
export const fetchWritingInstruction = createAsyncThunk(
  'writingInstruction/fetchInstruction',
  async (id: string) => {
    // Get instruction by id
    const writingInstructionsApi = await getApi(WritingInstructionApi);
    const resJobData = await writingInstructionsApi.getWritingInstruction(id);
    console.info('resJobData:', resJobData);
    const transformedData: WritingInstructionState = {
      ...initialState,
      isEdit: true,
      data: resJobData.data,
      userInstructionObj: stringToSlate(resJobData.data.userInstruction),
    };

    return transformedData;
  },
);

export const writingInstruction: ReturnType<typeof createSlice> = createSlice({
  name: 'writingInstruction',
  initialState,
  reducers: {
    changeInstructionTitle: (state, { payload }: PayloadAction<string>) => {
      state.data.title = payload;
    },
    changeInstructionDescription: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.data.description = payload;
    },
    changeUserInstruction: (
      state,
      { payload }: PayloadAction<Descendant[]>,
    ) => {
      state.userInstructionObj = payload;
      state.data.userInstruction = slateToString(payload);
    },
    changeDetailInstruction: (state, { payload }: PayloadAction<string>) => {
      state.data.detailInstruction = payload;
    },
    addNewInstructionExample: (state, { payload }: PayloadAction<string>) => {
      state.data.examples.push(payload);
    },
    changeInstructionExample: (
      state,
      { payload }: PayloadAction<{ index: number; value: string }>,
    ) => {
      state.data.examples[payload.index] = payload.value;
    },
    removeInstructionExample: (state, { payload }: PayloadAction<number>) => {
      state.data.examples.splice(payload, 1);
    },
    resetInstruction: (state) => {
      state.data = initialState.data;
      state.userInstructionObj = INIT_USER_INSTRUCTION;
      state.isEdit = false;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWritingInstruction.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWritingInstruction.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.loading = false;
      })
      .addCase(fetchWritingInstruction.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const writingInstructionActions = writingInstruction.actions;
export default writingInstruction.reducer;
