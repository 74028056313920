/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
  Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '@ink-ai/portal/common/utils';
import { RootState } from '@ink-ai/portal/reducers';
import { CreateReferenceStoreResponseDtoStatusEnum } from '@ink-ai/insight-service-sdk';

export const ReferenceStoreDetail: React.FC = () => {
  const formatter = new Intl.NumberFormat('en-US');
  const referenceStore = useSelector(
    (state: RootState) => state.referenceStore,
  );
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          color="inherit"
          onClick={() => navigate('/')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          Home
        </Link>
        <Link
          color="inherit"
          onClick={() => navigate('/reference-store')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          Reference Store
        </Link>
        <Typography color="textPrimary">
          {referenceStore.selectedReferenceStore?.name || 'Loading...'}
        </Typography>
      </Breadcrumbs>

      <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" sx={{ textAlign: 'left' }}>
          {referenceStore.selectedReferenceStore?.name || 'Loading...'}
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: 'left', mb: 2 }}>
          Reference Store settings
        </Typography>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              Terms
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {referenceStore.selectedReferenceStore?.status ===
              CreateReferenceStoreResponseDtoStatusEnum.Ready
                ? formatter.format(
                    referenceStore.selectedReferenceStore?.itemCount,
                  )
                : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              Languages
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {referenceStore.selectedReferenceStore?.status ===
              CreateReferenceStoreResponseDtoStatusEnum.Ready
                ? referenceStore.selectedReferenceStore?.languages.join(', ')
                : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              Status
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {referenceStore.selectedReferenceStore?.status
                .charAt(0)
                .toUpperCase() +
                referenceStore.selectedReferenceStore?.status
                  .slice(1)
                  .toLowerCase()}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              Storage
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {'OpenSearch'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              Index name
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {referenceStore.selectedReferenceStore?.indexName || 'N/A'}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              Updated at
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', color: 'gray' }}
            >
              {formatDate(referenceStore.selectedReferenceStore?.updatedAt)}
            </Typography>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
