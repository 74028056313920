/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { GlossaryItemTemplateDto } from '@ink-ai/portal/reducers/glossary';

export const glossaryJsonTemplate: GlossaryItemTemplateDto[] = [
  {
    name: 'Hypertension',
    desc: 'A condition in which the force of the blood against the artery walls is too high.',
    abbr: 'HTN',
    translations: [
      { language: 'ZH_CN', translation: '高血压' },
      { language: 'EN_US', translation: 'Hypertension' },
    ],
  },
  {
    name: 'Diabetes Mellitus',
    desc: 'A group of diseases that result in too much sugar in the blood (high blood glucose).',
    abbr: 'DM',
    translations: [
      { language: 'ZH_CN', translation: '糖尿病' },
      { language: 'EN_US', translation: 'Diabetes Mellitus' },
    ],
  },
  {
    name: 'Asthma',
    desc: "A condition in which a person's airways become inflamed, narrow and swell, and produce extra mucus, which makes it difficult to breathe.",
    abbr: 'Asthma',
    translations: [
      { language: 'ZH_CN', translation: '哮喘' },
      { language: 'EN_US', translation: 'Asthma' },
    ],
  },
  {
    name: 'Hyperlipidemia',
    desc: 'A condition in which there are high levels of fat particles (lipids) in the blood.',
    abbr: 'HLD',
    translations: [
      { language: 'ZH_CN', translation: '高脂血症' },
      { language: 'EN_US', translation: 'Hyperlipidemia' },
    ],
  },
];

export const glossaryCSVTemplate = `name,desc,abbr,ZH_CN,EN_US,JA
Hypertension,A condition in which the force of the blood against the artery walls is too high.,HTN,高血压,Hypertension,高血圧
Diabetes Mellitus,A group of diseases that result in too much sugar in the blood (high blood glucose).,DM,糖尿病,Diabetes Mellitus,糖尿病
Asthma,"A condition in which a person's airways become inflamed, narrow and swell, and produce extra mucus, which makes it difficult to breathe.",Asthma,哮喘,Asthma,喘息`;
