/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

export const referenceStoreCSVTemplate = `ZH_CN,EN_US,JA
"患者因血压持续升高（收缩压180mmHg，舒张压105mmHg），伴有头痛头晕症状，诊断为原发性高血压3级（极高危）","Patient presented with persistent elevated blood pressure (180/105mmHg), accompanied by headache and dizziness, diagnosed as Grade 3 Essential Hypertension (very high risk)","持続的な血圧上昇（収縮期血圧180mmHg、拡張期血圧105mmHg）、頭痛めまいを伴う症状により、本態性高血圧症グレード3（超高リスク）と診断された"
"患者空腹血糖8.9mmol/L，糖化血红蛋白7.8%，伴口渴多饮、多尿症状，确诊为2型糖尿病（CPC-E11.9）","Patient with fasting plasma glucose 8.9mmol/L, HbA1c 7.8%, presenting with polydipsia and polyuria, confirmed Type 2 Diabetes Mellitus (CPC-E11.9)","空腹時血糖値8.9mmol/L、HbA1c 7.8%、口渇・多飲・多尿症状を伴い、2型糖尿病（CPC-E11.9）と確定診断された"
"患者出现急性呼吸困难、喘息和胸闷，肺部可闻及广泛性哮鸣音，符合支气管哮喘急性发作（中度），PEF预计值的65%","Patient presenting with acute dyspnea, wheezing and chest tightness, diffuse wheezing on lung auscultation, consistent with Moderate Acute Exacerbation of Bronchial Asthma, PEF 65% of predicted value","急性呼吸困難、喘鳴、胸部絞扼感を呈し、肺野に広範性喘鳴を聴取、気管支喘息の中等度急性増悪、PEF予測値の65%と診断された"`;
