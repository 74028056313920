/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { GlossaryItemResponseDto, Language } from '@ink-ai/insight-service-sdk';

interface CreateGlossaryItemDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (item: any) => void;
  editingItem?: GlossaryItemResponseDto;
}

export const CreateUpdateGlossaryItemDialog: React.FC<
  CreateGlossaryItemDialogProps
> = ({ open, onClose, onCreate, editingItem }) => {
  const [newItem, setNewItem] = useState({
    name: '',
    desc: '',
    abbr: '',
    trans: [{ language: '', translation: '' }],
  });

  const [errors, setErrors] = useState({
    name: false,
    trans: false,
  });

  useEffect(() => {
    if (open) {
      setErrors({ name: false, trans: false });
      if (editingItem) {
        const { name = '', desc = '', abbr = '', translations } = editingItem;
        setNewItem({
          name,
          desc,
          abbr,
          trans: translations
            ? Object.values(translations).map(({ language, translation }) => ({
                language,
                translation,
              }))
            : [{ language: '', translation: '' }],
        });
      } else {
        setNewItem({
          name: '',
          desc: '',
          abbr: '',
          trans: [{ language: '', translation: '' }],
        });
      }
    }
  }, [open, editingItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });
    if (name === 'name' && value.trim() !== '') {
      setErrors((prev) => ({ ...prev, name: false }));
    }
  };

  const handleTranslationChange = (index, key, value) => {
    const updatedTranslations = newItem.trans.map((translation, i) =>
      i === index ? { ...translation, [key]: value } : translation,
    );
    setNewItem({ ...newItem, trans: updatedTranslations });

    if (key === 'language' && value.trim() !== '') {
      setErrors((prev) => ({ ...prev, trans: false }));
    }
  };

  const handleAddTranslation = () => {
    setNewItem({
      ...newItem,
      trans: [...newItem.trans, { language: '', translation: '' }],
    });
  };

  const handleRemoveTranslation = (index) => {
    const updatedTranslations = newItem.trans.filter((_, i) => i !== index);
    setNewItem({ ...newItem, trans: updatedTranslations });
  };

  const handleSubmit = () => {
    const isNameValid = newItem.name.trim() !== '';
    const isTransValid = newItem.trans.some(
      (translation) => translation.language.trim() !== '',
    );

    setErrors({
      name: !isNameValid,
      trans: !isTransValid,
    });

    if (!isNameValid || !isTransValid) return;

    onCreate(newItem);
    setNewItem({
      name: '',
      desc: '',
      abbr: '',
      trans: [{ language: '', translation: '' }],
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{editingItem ? 'Edit Item' : 'New Item'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          variant="standard"
          label="Name"
          type="text"
          fullWidth
          name="name"
          helperText={errors.name ? 'Name is required' : 'Input a name'}
          error={errors.name}
          value={newItem.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          variant="standard"
          label="Description (optional)"
          type="text"
          fullWidth
          name="desc"
          helperText="Input description"
          value={newItem.desc}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          variant="standard"
          label="Abbreviation (optional)"
          type="text"
          fullWidth
          name="abbr"
          helperText="Input abbreviation"
          value={newItem.abbr}
          onChange={handleChange}
        />
        {newItem.trans.map((translation, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <FormControl
              variant="standard"
              style={{ marginRight: '8px', minWidth: 120 }}
              error={errors.trans}
            >
              <InputLabel>Language</InputLabel>
              <Select
                value={translation.language}
                onChange={(e) =>
                  handleTranslationChange(index, 'language', e.target.value)
                }
              >
                <MenuItem value={Language.ZhCn}>Chinese</MenuItem>
                <MenuItem value={Language.EnUs}>English</MenuItem>
                <MenuItem value={Language.Ja}>Japanese</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              variant="standard"
              label="Translation"
              type="text"
              fullWidth
              value={translation.translation}
              onChange={(e) =>
                handleTranslationChange(index, 'translation', e.target.value)
              }
            />
            <IconButton
              onClick={() => handleRemoveTranslation(index)}
              style={{ marginLeft: '8px' }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        {errors.trans && (
          <p style={{ color: 'red', marginBottom: '8px' }}>
            At least one language must be selected
          </p>
        )}
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddTranslation}
          style={{ marginTop: '8px' }}
        >
          Add Translation
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {editingItem ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
