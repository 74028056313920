/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Link,
  Breadcrumbs,
  Alert,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  createGlossaryFromParsedFile,
  updateGlossaryFromParsedFile,
  glossaryActions,
  GlossaryItemTemplateDto,
  ImportFileParseStatus,
  importExternalGlossary,
} from '../../reducers/glossary';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStore, RootState } from '@ink-ai/portal/reducers';
import {
  glossaryJsonTemplate,
  glossaryCSVTemplate,
} from '../components/glossary/GlossaryTemplate';
import LoadingButton from '@mui/lab/LoadingButton';
import { app } from '@ink-ai/portal/reducers/app';
import Papa from 'papaparse';
import { useNavigationListener } from '../components/hooks/useNavigationListener';
import {
  downloadFile,
  formatFileSize,
  glossaryJsonValidate,
} from '@ink-ai/portal/common/utils';
import { EllipsisCell } from '@ink-ai/portal/common/components/paginated-table/EllipsisCell';
import { PaginatedTable } from '@ink-ai/portal/common/components/paginated-table/PaginatedTable';
import {
  GlossaryCollectionDtoTypeEnum,
  Language,
} from '@ink-ai/insight-service-sdk';

const externalGlossarySizeLimit = 10 * 1024 * 1024;
const externalGlossaryItemCountLimit = 2000;

const downloadJsonTemplate = () => {
  downloadFile(
    JSON.stringify(glossaryJsonTemplate, null, 2),
    'glossary-template.json',
    'application/json',
  );
};

const downloadCSVTemplate = () => {
  downloadFile(
    `\uFEFF${glossaryCSVTemplate}`, // Using BOM to force Excel to open CSV in UTF-8
    'glossary-template.csv',
    'text/csv',
  );
};

interface ImportGlossaryProps {
  glossaryUuid?: string;
  glossaryName?: string;
  glossaryDescription?: string;
  glossaryType?: GlossaryCollectionDtoTypeEnum;
}

interface CsvItem {
  name: string;
  desc: string;
  abbr: string;
  ZH_CN: string;
  EN_US: string;
  JA: string;
}

export const ImportGlossary: React.FC<ImportGlossaryProps> = () => {
  const dispatch = useDispatch<any>();
  const store = getStore();
  const location = useLocation();
  const { glossaryUuid, glossaryName, glossaryDescription, glossaryType } =
    location.state || {};
  const navigate = useNavigate();
  const glossary = useSelector((state: RootState) => state.glossary);
  const [name, setName] = useState(glossaryName);
  const [description, setDescription] = useState(glossaryDescription);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isParsing, setIsParsing] = useState(false);
  const [itemCount, setItemCount] = useState(0);

  useNavigationListener();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      glossary.importFileParseStatus === ImportFileParseStatus.Uploading &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  const columns = [
    {
      id: 'name',
      label: 'Name',
      render: (item: GlossaryItemTemplateDto) => (
        <EllipsisCell content={item.name} />
      ),
      width: 150,
    },
    {
      id: 'description',
      label: 'Description',
      render: (item: GlossaryItemTemplateDto) => (
        <EllipsisCell content={item.desc || '-'} />
      ),
      width: 150,
    },
    {
      id: 'abbreviation',
      label: 'Abbreviation',
      render: (item: GlossaryItemTemplateDto) => (
        <EllipsisCell content={item.abbr || '-'} />
      ),
      width: 150,
    },
    {
      id: 'translations',
      label: 'Translations',
      render: (item: GlossaryItemTemplateDto) => (
        <Box>
          {Object.entries(item.translations).map(([key, value]) => (
            <div key={key}>
              {value.language}: {value.translation}
            </div>
          ))}
        </Box>
      ),
      width: 150,
    },
  ];

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };
  const handleFileUpload = async (file: File) => {
    setFileName(file.name);
    setFileSize(file.size);
    setSelectedFile(file);
    setIsParsing(true);

    if (file.size > externalGlossarySizeLimit) {
      console.log(
        'File size exceeds the internal db limit, use external db for large files',
      );
      setIsParsing(false);
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        try {
          const fileContent = e.target.result as string;
          if (glossaryType === GlossaryCollectionDtoTypeEnum.External) {
            if (!file.name.endsWith('.csv')) {
              throw new Error(
                'Only CSV files are allowed for external glossaries',
              );
            }
            handleCsvParse(fileContent);
          } else {
            if (file.name.endsWith('.json')) {
              handleJsonParse(fileContent);
            } else if (file.name.endsWith('.csv')) {
              handleCsvParse(fileContent);
            } else {
              throw new Error('Unsupported file format');
            }
          }
        } catch (error) {
          console.log('error: ', error);
          store.dispatch(
            app.actions.setGlobalMessage({
              message:
                'Failed to parse the file. Please ensure it is a valid JSON or CSV.',
              status: 'error',
            }),
          );
          handleFileRemove();
        } finally {
          setIsParsing(false);
        }
      }
    };
    reader.onerror = () => {
      store.dispatch(
        app.actions.setGlobalMessage({
          message: 'Failed to read the file. Please try again.',
          status: 'error',
        }),
      );
      handleFileRemove();
    };
    reader.readAsText(file);
  };

  const handleJsonParse = (fileContent: string) => {
    const content = JSON.parse(fileContent);
    if (glossaryJsonValidate(content)) {
      setItemCount(content.length);
      if (content.length > externalGlossaryItemCountLimit) {
        console.log(
          `Error: Number of items exceeds ${externalGlossaryItemCountLimit}`,
        );
        throw new Error(
          `Number of glossary items exceeds the limit of ${externalGlossaryItemCountLimit}`,
        );
      }
      dispatch(
        glossaryActions.setImportFileParseStatus({
          status: ImportFileParseStatus.Complete,
          content,
        }),
      );
    } else {
      setItemCount(0);
      console.log('Validation errors: ', glossaryJsonValidate.errors);
      throw new Error('Invalid JSON format');
    }
  };

  const handleCsvParse = (fileContent: string) => {
    Papa.parse<CsvItem>(fileContent, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const content = result.data.map((item) => {
          const translations = Object.values(Language).reduce(
            (acc, lang) => {
              if (item[lang]) {
                acc.push({ language: lang, translation: item[lang] });
              }
              return acc;
            },
            [] as Array<{ language: string; translation: string }>,
          );

          return {
            name: item.name,
            desc: item.desc,
            abbr: item.abbr,
            translations,
          };
        });
        setItemCount(content.length);
        dispatch(
          glossaryActions.setImportFileParseStatus({
            status: ImportFileParseStatus.Complete,
            content: content as GlossaryItemTemplateDto[],
          }),
        );
      },
      error: () => {
        store.dispatch(
          app.actions.setGlobalMessage({
            message:
              'Failed to parse the CSV file. Please ensure it is a valid CSV.',
            status: 'error',
          }),
        );
        handleFileRemove();
        setItemCount(0);
      },
    });
  };

  const handleImportGlossary = (isUseExternalDb: boolean) => {
    dispatch(glossaryActions.setLoading(true));
    if (glossaryUuid) {
      if (isUseExternalDb) {
        dispatch(
          importExternalGlossary({
            uuid: glossaryUuid,
            name,
            description,
            file: selectedFile,
          }),
        );
      } else {
        dispatch(
          updateGlossaryFromParsedFile({ glossaryUuid, name, description }),
        );
      }
    } else {
      if (isUseExternalDb) {
        dispatch(
          importExternalGlossary({ name, description, file: selectedFile }),
        );
      } else {
        dispatch(createGlossaryFromParsedFile({ name, description }));
      }
    }
  };

  const handleFileRemove = () => {
    setFileName('');
    setFileSize(0);
    dispatch(
      glossaryActions.setImportFileParseStatus({
        status: ImportFileParseStatus.WaitingUpload,
        content: null,
      }),
    );
    setSelectedFile(null);
    dispatch(glossaryActions.setLoading(false));
  };

  const handleCancel = () => {
    navigate('/glossary');
  };

  useEffect(() => {
    // Clean up function to reset glossary state when component unmounts
    return () => {
      handleFileRemove();
    };
  }, []);

  const parsedItems = glossary.parsedFileContent || [];

  const isFileTooLarge = fileSize > externalGlossarySizeLimit;
  const isItemCountExceeded = itemCount > 2000;
  const isUseExternalDb =
    isFileTooLarge ||
    isItemCountExceeded ||
    glossaryType === GlossaryCollectionDtoTypeEnum.External;

  const showUploadBox = () => {
    if (!glossaryUuid) {
      // Create glossary scenario
      if (isFileTooLarge || isItemCountExceeded) {
        return false;
      } else {
        return !itemCount;
      }
    } else {
      // Update glossary scenario
      if (glossaryType !== GlossaryCollectionDtoTypeEnum.External) {
        // Not external DB
        return !itemCount;
      } else {
        // External DB
        if (isFileTooLarge || isItemCountExceeded) {
          return false;
        } else {
          return !itemCount;
        }
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          color="inherit"
          onClick={() => navigate('/glossary')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          Glossary
        </Link>
        <Typography color="textPrimary">Import</Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ textAlign: 'left' }}>
          {glossaryUuid ? 'Update' : 'Import'} Glossary
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mb: 3, textAlign: 'left' }}
        >
          {glossaryUuid
            ? 'Updating an existing glossary by importing from a file.'
            : 'Creating a glossary by importing from a file.'}
        </Typography>
        <Box sx={{ width: '50%' }}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={name}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Description (Optional)"
            variant="outlined"
            fullWidth
            sx={{ mb: 1 }}
            value={description}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Typography variant="body2" sx={{ mb: 1, textAlign: 'left' }}>
            Download the{' '}
            {glossaryType !== GlossaryCollectionDtoTypeEnum.External ? (
              <>
                <Link href="#" onClick={downloadJsonTemplate}>
                  JSON template
                </Link>
                {' or '}
              </>
            ) : null}
            <Link href="#" onClick={downloadCSVTemplate}>
              CSV template
            </Link>
            , and import.
          </Typography>
          <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            sx={{
              border: '1px dashed grey',
              p: 2,
              mb: 2,
              textAlign: 'center',
              borderRadius: '4px',
              borderColor: 'grey.400',
            }}
          >
            <input
              accept={
                glossaryType === GlossaryCollectionDtoTypeEnum.External
                  ? '.csv'
                  : '.json, .csv'
              }
              className="hidden"
              id="upload-file"
              type="file"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  handleFileUpload(file);
                }
              }}
            />
            {isParsing ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                }}
              >
                <CircularProgress />
              </Box>
            ) : showUploadBox() ? (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 2,
                  }}
                >
                  <UploadFileIcon
                    sx={{
                      fontSize: '24px',
                      color: '#2196F3',
                    }}
                  />
                </Box>
                <label htmlFor="upload-file" className="cursor-pointer">
                  <Link component="span" underline="hover">
                    Click to upload
                  </Link>
                </label>
                &nbsp;or drag and drop
                <Typography variant="body2" color="text.secondary">
                  {glossaryType === GlossaryCollectionDtoTypeEnum.External
                    ? '.csv'
                    : '.json or .csv'}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 1,
                  }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                  >
                    <InsertDriveFileIcon sx={{ mr: 3, color: '#2196F3' }} />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Typography variant="body2">{fileName}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {formatFileSize(fileSize)} •{' '}
                        {glossary.importFileParseStatus}
                      </Typography>
                    </Box>
                  </Box>
                  <DeleteIcon
                    sx={{ cursor: 'pointer', color: '#0000008F', ml: 2 }}
                    onClick={handleFileRemove}
                  />
                </Box>
                {glossary.importFileParseStatus ===
                  ImportFileParseStatus.Uploading && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress
                      variant="determinate"
                      value={glossary.progress}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>

        {isUseExternalDb && (
          <Box sx={{ mt: 2, textAlign: 'left' }}>
            <Alert
              severity="info"
              sx={{
                mb: 2,
                display: 'flex',
                width: '60%',
                border: '1px solid #2196f3',
                backgroundColor: '#fff',
                paddingRight: '40px',
              }}
            >
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {glossaryType === GlossaryCollectionDtoTypeEnum.External
                    ? 'Updating External Glossary'
                    : 'Large-sized glossary'}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {glossaryType === GlossaryCollectionDtoTypeEnum.External
                    ? 'You are updating an external glossary. The changes will be applied to the external database.'
                    : "Your glossary contains over 2,000 terms, which exceeds our in-memory database limit. To accommodate your extensive terminology, we'll import into an OpenSearch index for efficient processing."}
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Please note:
                </Typography>
                <ul
                  style={{
                    paddingLeft: '16px',
                    listStyleType: 'disc',
                  }}
                >
                  <li>
                    <Typography variant="body2">
                      Due to the large size, you won&apos;t be able to view or
                      edit individual glossary entries through the user
                      interface.
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Alert>
          </Box>
        )}

        {!isUseExternalDb && parsedItems.length > 0 && (
          <PaginatedTable
            dummyPagination
            columns={columns}
            data={parsedItems}
            selectedItems={[]}
            page={page}
            rowsPerPage={rowsPerPage}
            loading={false}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-start',
          }}
        >
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => handleImportGlossary(isUseExternalDb)}
            disabled={
              !name ||
              !fileName ||
              glossary.importFileParseStatus === ImportFileParseStatus.Error
            }
            sx={{ width: '99px' }}
            loading={glossary.loading}
          >
            {glossaryUuid ? 'Update' : 'Import'}
          </LoadingButton>
          <Button onClick={handleCancel} sx={{ ml: 1, width: '99px' }}>
            Cancel
          </Button>
        </Box>
      </Paper>

      <Dialog open={blocker.state === 'blocked'}>
        <DialogTitle>Uploading Document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The document is currently being uploaded. Please refrain from
            navigating away from this page until the upload process is fully
            completed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => blocker.proceed()}>Leave anyway</Button>
          <Button
            onClick={() => blocker.reset()}
            color="primary"
            variant="contained"
            autoFocus
          >
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
