/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useMemo } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { startCompose, compose } from '../../../reducers/compose';
import { TranslateRequestDtoTargetLanguageEnum } from '@ink-ai/insight-service-sdk';
import { LoadingButton } from '@mui/lab';
import TranslateIcon from '@mui/icons-material/Translate';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { copyText } from '@ink-ai/portal/common/utils';
import { CharacterCount } from '../../../common/components/CharacterCount';
import { translationActions } from '@ink-ai/portal/reducers/translation';

interface TextTranslateAreaProps {
  sourceLanguage: string;
  targetLanguage: string;
}

const maxTextLength = 5000;

export const TextTranslateArea: React.FC<TextTranslateAreaProps> = ({
  sourceLanguage,
  targetLanguage,
}) => {
  const dispatch = useDispatch<any>();
  const composeState = useSelector((state: RootState) => state.compose);
  const translationState = useSelector((state: RootState) => state.translation);
  const translatedText = composeState.generatedText;
  const sourceText = translationState.sourceText;

  const isInputTextValid = useMemo(() => {
    if (!sourceText || sourceText.length === 0) {
      return false;
    }
    if (sourceText.length > maxTextLength) {
      return false;
    }
    return true;
  }, [sourceText]);

  useEffect(() => {
    return () => {
      dispatch(compose.actions.clearAll());
    };
  }, []);

  const handleSourceTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    dispatch(translationActions.setSourceText(event.target.value));
  };

  const handleCopyText = async (text: string) => {
    copyText(text);
  };

  const handleTranslate = () => {
    dispatch(
      startCompose({
        task: 'translate',
        text: sourceText,
        sourceLanguage: sourceLanguage as TranslateRequestDtoTargetLanguageEnum,
        targetLanguage: targetLanguage as TranslateRequestDtoTargetLanguageEnum,
        glossaryIdList: translationState.selectedGlossaries,
        referenceStoreIdList: translationState.selectedReferenceStores,
      }),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 280px)',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, height: '100%' }}>
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <TextareaAutosize
            style={{
              width: '100%',
              height: '100%',
              resize: 'none',
              padding: '16.5px 14px',
              boxSizing: 'border-box',
              overflow: 'auto',
              border: '1px solid #ccc',
            }}
            value={sourceText}
            onChange={handleSourceTextChange}
          />
          <Typography
            sx={{
              position: 'absolute',
              bottom: 12,
              right: 17,
              zIndex: 1,
              color: sourceText.length > maxTextLength ? 'red' : 'gray',
            }}
          >
            <CharacterCount current={sourceText.length} max={maxTextLength} />
          </Typography>
        </Box>
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <TextareaAutosize
            style={{
              width: '100%',
              height: '100%',
              resize: 'none',
              padding: '16.5px 14px',
              boxSizing: 'border-box',
              backgroundColor: '#f5f5f5',
              overflow: 'auto',
            }}
            placeholder="Translation"
            value={translatedText}
            readOnly
          />
          <IconButton
            onClick={() => handleCopyText(translatedText)}
            sx={{
              position: 'absolute',
              bottom: 8,
              right: 8,
              zIndex: 1,
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Box>
      </Box>
      <LoadingButton
        variant="contained"
        loadingPosition="start"
        color="primary"
        onClick={handleTranslate}
        sx={{ alignSelf: 'flex-end', mt: 1, textTransform: 'none' }}
        disabled={!isInputTextValid || composeState.isGenerating}
        loading={composeState.isGenerating}
        startIcon={
          composeState.isGenerating ? (
            <TranslateIcon /> // this icon is useless, just for placeholder
          ) : undefined
        }
      >
        Translate
      </LoadingButton>
    </Box>
  );
};
