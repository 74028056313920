/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApi } from '../common/requestHelper';
import {
  ConnectorApi,
  ImportConnectorResponseDto,
  ListConnectorResponseDto,
} from '@ink-ai/insight-service-sdk';
import { app } from './app';

export type ConnectorState = {
  connectors: ImportConnectorResponseDto[];
  loading: boolean;
};

const initialState: ConnectorState = {
  connectors: [],
  loading: false,
};

export const listConnectors = createAsyncThunk<ListConnectorResponseDto>(
  'connector/listConnectors',
  async () => {
    const connectorApi = await getApi(ConnectorApi);
    const response = await connectorApi.listConnectors();
    return response.data;
  },
);

export const deleteConnector = createAsyncThunk(
  'connector/deleteConnector',
  async (connectorIds: string[], { getState, dispatch }) => {
    try {
      const state = getState() as { connector: ConnectorState };
      const connectorApi = await getApi(ConnectorApi);

      const deletePromises = connectorIds.map(async (uuid) => {
        const selectedConnector = state.connector.connectors.find(
          (g) => g.uuid === uuid,
        );

        if (selectedConnector) {
          await connectorApi.deleteConnector(selectedConnector.uuid);
        }
      });

      await Promise.all(deletePromises);
      dispatch(listConnectors());
    } catch (error: any) {
      console.error('Error occurred during deleteConnector:', error);
    }
  },
);

export const importConnector = createAsyncThunk(
  'connector/importConnector',
  async (connectorName: string, { dispatch }) => {
    try {
      const connectorApi = await getApi(ConnectorApi);
      await connectorApi.importConnector({ name: connectorName });
      dispatch(
        app.actions.setGlobalMessage({
          message: 'Connector imported successfully.',
          status: 'success',
        }),
      );
      dispatch(app.actions.setNavigationPath('/settings/connectors'));
    } catch (error: any) {
      console.error('Error occurred during importConnector:', error);
      dispatch(
        app.actions.setGlobalMessage({
          message: 'Failed to import the connector.' + error.message,
          status: 'error',
        }),
      );
    }
    dispatch(connector.actions.setLoading(false));
  },
);

export const connector = createSlice({
  name: 'connector',
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listConnectors.pending, (state) => {
        state.loading = true;
      })
      .addCase(listConnectors.fulfilled, (state, action) => {
        state.connectors = action.payload.connectors;
        state.loading = false;
      })
      .addCase(listConnectors.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const connectorActions = connector.actions;
