/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useMemo, useState } from 'react';
import { GlossaryItemResponseDto } from '@ink-ai/insight-service-sdk';
import { EllipsisCell } from '../../../common/components/paginated-table/EllipsisCell';
import { TranslationsCell } from '../../../common/components/paginated-table/TranslationsCell';
import { PaginatedTable } from '../../../common/components/paginated-table/PaginatedTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGlossaryById,
  listGlossaryItemsById,
} from '../../../reducers/glossary';
import { RootState } from '../../../reducers';

interface GlossaryItemTableProps {
  glossaryId: string;
  selectedItems: string[];
  searchTerm: string;
  page: number;
  onPageChange: (page: number) => void;
  onSelectedItemsChange: (selectedItems: string[]) => void;
}

export const GlossaryItemTable: React.FC<GlossaryItemTableProps> = ({
  glossaryId,
  selectedItems,
  searchTerm,
  page,
  onPageChange,
  onSelectedItemsChange,
}) => {
  const dispatch = useDispatch<any>();
  const glossary = useSelector((state: RootState) => state.glossary);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (glossaryId) {
      dispatch(listGlossaryItemsById(glossaryId));
      dispatch(getGlossaryById(glossaryId));
    }
  }, [dispatch, glossaryId]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const currentPageGlossaries = filteredItems.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      );
      const newSelecteds = currentPageGlossaries.map((g) => g.uuid);
      onSelectedItemsChange(newSelecteds);
    } else {
      onSelectedItemsChange([]);
    }
  };

  const handleClick = (uuid: string) => {
    const selectedIndex = selectedItems.indexOf(uuid);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1),
      );
    }

    onSelectedItemsChange(newSelected);
  };

  const handlePageChange = (_: unknown, newPage: number) => {
    onPageChange(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageChange(0);
  };

  const filteredItems = useMemo(
    () =>
      (glossary.glossaryItems || []).filter((g) =>
        g.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [glossary.glossaryItems, searchTerm],
  );

  const columns = [
    {
      id: 'name',
      label: 'Name',
      render: (item: GlossaryItemResponseDto) => (
        <EllipsisCell content={item.name} />
      ),
      width: 150,
    },
    {
      id: 'description',
      label: 'Description',
      render: (item: GlossaryItemResponseDto) => (
        <EllipsisCell content={item.desc || '-'} />
      ),
      width: 150,
    },
    {
      id: 'abbreviation',
      label: 'Abbreviation',
      render: (item: GlossaryItemResponseDto) => (
        <EllipsisCell content={item.abbr || '-'} />
      ),
      width: 150,
    },
    {
      id: 'translations',
      label: 'Translations',
      render: (item: GlossaryItemResponseDto) => (
        <TranslationsCell translations={item.translations} />
      ),
      width: 150,
    },
  ];

  return (
    <PaginatedTable
      dummyPagination
      columns={columns}
      data={filteredItems}
      selectedItems={selectedItems}
      page={page}
      rowsPerPage={rowsPerPage}
      loading={glossary.loading}
      getItemId={(item) => item.uuid}
      onSelectAllClick={handleSelectAllClick}
      onItemClick={handleClick}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};
