/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ChatApi } from '@ink-ai/insight-service-sdk';
import { RootState } from '.';
import { getApi } from '../common/requestHelper';
import { Message } from '../taskpane/components/Chat/ChatBlock';

export type ChatStreamResponseDto = {
  id: string;
  text: string;
  isFinal: boolean;
};

const initialState = {
  loading: false,
  chatId: uuidv4(),
  responseText: '',
  messageList: [] as Message[],
  inputText: '', // user current input text
  selectedText: '', // user selected text
  lastSelectedText: '', // user last selected text
  currentHistoryIndex: -1,
  isSearchingHistory: false,
  currentChatId: '',
};

const createWelcomeMessage = (): Message => ({
  id: uuidv4(),
  text: 'Hi. I am your writing companion. Select a sentence or a paragraph to start interacting with me, or just ask.',
  type: 'system',
});

export type ChatState = typeof initialState;

export const createChat = createAsyncThunk(
  'chat/createChat',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    if (state.chat.inputText.trim() === '') {
      return null;
    }
    dispatch(chat.actions.changeLoading(true));
    try {
      const chatApi = await getApi(ChatApi);

      dispatch(chat.actions.handleHumanSubmit());
      dispatch(chat.actions.clearInputText());

      const chatResponseId = uuidv4();
      dispatch(chat.actions.insertThinkingMessageBubble(chatResponseId));

      const response = await chatApi.createChat({
        instanceId: state.auth.instanceId,
        context: state.chat.selectedText,
        text: state.chat.inputText,
        chatId: state.chat.chatId,
        chatResponseId: chatResponseId,
        async: true,
      });

      return response.data;
    } catch (error: any) {
      console.error('Error creating chat:', error);
      dispatch(chat.actions.changeLoading(false));
      return null;
    } finally {
      dispatch(chat.actions.clearInputText());
    }
  },
);

export const chat = createSlice({
  name: 'chat',
  initialState: initialState,
  reducers: {
    addWelcomeMessage: (state) => {
      state.messageList = [createWelcomeMessage(), ...state.messageList];
    },
    handleHumanSubmit: (state) => {
      const userMessage: Message = {
        id: uuidv4(),
        text: state.inputText,
        type: 'human',
      };
      state.messageList.push(userMessage);
    },
    insertThinkingMessageBubble: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      const thinkingMessage: Message = {
        id: payload,
        text: '',
        type: 'bot',
      };
      state.messageList.push(thinkingMessage);
      // Set the current chat id to the thinking message id
      // This is used to enable blinking cursor for the thinking message
      state.currentChatId = payload;
    },

    updateChatResult: (
      state,
      { payload }: PayloadAction<ChatStreamResponseDto>,
    ) => {
      state.currentChatId = payload.id;
      const existingItemIndex = state.messageList.findIndex(
        (item) => item.id === payload.id,
      );

      if (existingItemIndex === -1) {
        console.log(
          'Chat item not found, dropping message. This may be due to the old chat session has been cleared.',
        );
        return;
      }

      if (payload.isFinal) {
        // If isFinal is true, replace the message and set isRestRespReceived to true
        const updatedChatItem: Message = {
          id: payload.id,
          text: payload.text.trim(),
          type: 'bot',
          isRestRespReceived: true,
        };
        if (existingItemIndex !== -1) {
          state.messageList[existingItemIndex] = updatedChatItem;
        } else {
          state.messageList.push(updatedChatItem);
        }
        console.log('Final response received');
        state.loading = false;
        state.currentChatId = '';
      } else if (
        existingItemIndex !== -1 &&
        state.messageList[existingItemIndex]?.isRestRespReceived === true
      ) {
        return;
      } else {
        // If the message doesn't exist or isRestRespReceived is not true, proceed with the update
        const existingItem = state.messageList[existingItemIndex];
        const updatedChatItem: Message = {
          id: payload.id,
          text: ((existingItem?.text || '') + payload.text).trim(), // append streaming text to the existing text
          type: 'bot',
        };

        if (existingItemIndex !== -1) {
          state.messageList[existingItemIndex] = updatedChatItem;
        } else {
          state.messageList.push(updatedChatItem);
        }
      }
    },
    setSelectedText: (state, { payload }: PayloadAction<string>) => {
      // Check if the selected text is empty and the last message is a system message
      // This is to remove the last system message when the user deselects the text
      if (state.selectedText === payload) {
        return;
      }
      if (
        payload === '' &&
        state.messageList.length > 1 &&
        state.messageList[state.messageList.length - 1].type === 'system'
      ) {
        // Remove the last system message
        state.messageList.pop();
        state.selectedText = payload;
        return;
      }

      state.selectedText = payload;
      // Check if selected text length exceeds system limit characters
      const maxCharacters = 5000; // One word is 3.75 tokens
      if (state.selectedText.length > maxCharacters) {
        const errorMessage: Message = {
          id: uuidv4(),
          text: `The selected text is too long. Please select a shorter text. Supported text length is ${maxCharacters} characters.`,
          type: 'system',
        };
        // Check if the last message is a system message
        const lastMessage = state.messageList[state.messageList.length - 1];
        if (
          lastMessage &&
          lastMessage.type === 'system' &&
          state.messageList.length > 1
        ) {
          // Update the last system message
          lastMessage.text = errorMessage.text;
        } else {
          state.messageList.push(errorMessage);
        }
        return;
      }

      if (
        state.selectedText !== state.lastSelectedText &&
        state.selectedText !== ''
      ) {
        // Update the last selected text
        state.lastSelectedText = state.selectedText;

        // Shorten the selected text if it's too long
        const prefixLength = 40;
        const suffixLength = 20;
        const shortenedText =
          state.selectedText.length > prefixLength + suffixLength
            ? `${state.selectedText.substring(
                0,
                prefixLength,
              )}......${state.selectedText.substring(
                state.selectedText.length - suffixLength,
                state.selectedText.length,
              )}`
            : state.selectedText;
        // Check if the last message is a system message
        const lastMessage = state.messageList[state.messageList.length - 1];
        if (
          lastMessage &&
          lastMessage.type === 'system' &&
          state.messageList.length > 1
        ) {
          // Update the last system message
          lastMessage.text = `You have selected the following text: \n“${shortenedText}”. \nHow can I help you?`;
        } else {
          const systemMessage: Message = {
            id: uuidv4(),
            text: `You have selected the following text: \n“${shortenedText}”. \nHow can I help you?`,
            type: 'system',
          };
          state.messageList = [...state.messageList, systemMessage];
        }
        console.log('Selected text:', shortenedText);
      }
    },
    setInputText: (state, { payload }: PayloadAction<string>) => {
      state.inputText = payload;
    },
    resetSearchHistory: (state) => {
      state.isSearchingHistory = false;
      state.currentHistoryIndex = -1;
    },
    startSearchHistory: (state) => {
      state.isSearchingHistory = true;
      state.currentHistoryIndex = -1;
    },
    searchUp: (state) => {
      const humanTexts = state.messageList
        .filter(({ type }) => type === 'human')
        .reverse();
      if (state.currentHistoryIndex + 1 < humanTexts.length) {
        state.currentHistoryIndex += 1;
        state.inputText = humanTexts[state.currentHistoryIndex].text;
      }
    },
    searchDown: (state) => {
      const humanTexts = state.messageList
        .filter(({ type }) => type === 'human')
        .reverse();
      if (state.currentHistoryIndex - 1 >= 0) {
        state.currentHistoryIndex -= 1;
        state.inputText = humanTexts[state.currentHistoryIndex].text;
      } else {
        state.currentHistoryIndex = -1;
        state.inputText = '';
      }
    },
    changeLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    changeCurrentChatId(state, { payload }: PayloadAction<string>) {
      state.currentChatId = payload;
    },
    clearInputText: (state) => {
      state.inputText = '';
    },
    startNewChat: (state) => {
      state.chatId = uuidv4();
      state.messageList = [];
      state.inputText = '';
      state.selectedText = '';
      state.lastSelectedText = '';
      state.currentHistoryIndex = -1;
      state.isSearchingHistory = false;
      state.currentChatId = '';
      state.loading = false;
      state.messageList.push(createWelcomeMessage());
    },
    clearAll: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createChat.rejected, (state, action) => {
      console.error(action.error);
      state.loading = false;
    });
  },
});
