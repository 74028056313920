/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useState } from 'react';
import { Paper, Breadcrumbs, Typography, Link, Container } from '@mui/material';
import { TinyMceEditor } from '../components/editor/TinyMceEditor';
import { useDispatch, useSelector } from 'react-redux';
import { editor } from '../../reducers/editor';
import { useAiAssistant } from '../../common/hooks/useAiAssistant';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '@ink-ai/portal/reducers';
import { getApi } from '@ink-ai/portal/common/requestHelper';
import { ArticleApi } from '@ink-ai/insight-service-sdk';
import { article } from '@ink-ai/portal/reducers/article';
import { compose } from '@ink-ai/portal/reducers/compose';
import { chat } from '@ink-ai/portal/reducers/chat';

export const InsightComposer = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { id } = useParams();
  const articleTitle = useSelector(
    (state: RootState) => state.article.articleTitle,
  );
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!id) {
      return;
    }
    (async () => {
      const articleApi = await getApi(ArticleApi);
      dispatch(article.actions.setEditorLocked(true));
      try {
        const { data: articleContent } = await articleApi.getArticleContent(id);
        dispatch(article.actions.setArticleId(id));
        dispatch(article.actions.setCitations(articleContent.citations));
        setContent(articleContent.content);
      } finally {
        dispatch(article.actions.setEditorLocked(false));
      }
    })();
  }, [id, dispatch]);

  useEffect(() => {
    // Clean up
    return () => {
      dispatch(editor.actions.clearAll());
      dispatch(article.actions.clearAll());
      dispatch(compose.actions.clearAll());
      dispatch(chat.actions.clearAll());
    };
  }, [dispatch]);

  useAiAssistant();

  return (
    <Container maxWidth="lg" sx={{ mt: 0, mb: 0 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link
          color="inherit"
          onClick={() => navigate('/')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          Home
        </Link>
        <Link
          color="inherit"
          onClick={() => navigate('/articles')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          Articles
        </Link>
        <Typography color="textPrimary">
          {articleTitle || 'Untitled'}
        </Typography>
      </Breadcrumbs>
      <Paper
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TinyMceEditor content={content} />
      </Paper>
    </Container>
  );
};
