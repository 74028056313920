/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useCallback, useEffect } from 'react';
import {
  ArticleInsightCitationDto,
  ArticleInsightOutlineDto,
  ArticleInsightQueryKbDto,
  ArticleInsightSectionDto,
  GetDocumentTranslationResponseDto,
  InferenceResponseDto,
  SectionInsightDto,
} from '@ink-ai/insight-service-sdk';
import { RootState, getStore } from '../../reducers';
import { correction } from '../../reducers/correction';
import { getSocketConnection } from './socket';
import { useDispatch, useSelector } from 'react-redux';
import { webSocket } from '../../reducers/webSocket';
import { ChatStreamResponseDto, chat } from '../../reducers/chat';
import { compose } from '../../reducers/compose';
import { article } from '@ink-ai/portal/reducers/article';
import { writingStyle } from '../../reducers/writing-style';
import { useStateAndRef } from '../hooks/useStateAndRef';
import { translation } from '../../reducers/translation';
import { app } from '@ink-ai/portal/reducers/app';

export const enum WsMessageType {
  INFERENCE_RESULT = 'inference-result',
  CHAT_RESULT = 'chat-result',
  TEXT_GENERATION_RESULT = 'text-generation-result',
  TEXT_ANALYSIS_RESULT = 'text-analysis-result',
  DOCUMENT_TRANSLATION_RESULT = 'document-translation-result',
  TEXT_GENERATION_INSIGHT_QUERY_KB = 'text-generation-insight_on_query_kb',
  TEXT_GENERATION_INSIGHT_CITATION = 'text-generation-insight_on_citation',
  TEXT_GENERATION_INSIGHT_OUTLINE = 'text-generation-insight_on_outline',
  TEXT_GENERATION_INSIGHT_SECTION = 'text-generation-insight_on_section',
  TEXT_GENERATION_INSIGHT = 'text-generation-insight',
  SECTION_GENERATION_ON_CITATION = 'section-generation_on_citation',
  SECTION_GENERATION_ON_CONTENT = 'section-generation_on_content',
  SECTION_GENERATION_ON_QUERY_KB = 'section-generation_on_query_kb',
  SECTION_GENERATION = 'section-generation',
  SECTION_GENERATION_ON_FULL_CONTENT = 'section-generation_on_full_content',
}

export type wsData<T extends WsMessageType, P> = {
  type: T;
  payload: P;
};

export type wsResponse =
  | wsData<WsMessageType.INFERENCE_RESULT, InferenceResponseDto>
  | wsData<WsMessageType.CHAT_RESULT, ChatStreamResponseDto>
  | wsData<WsMessageType.TEXT_GENERATION_RESULT, ChatStreamResponseDto>
  | wsData<WsMessageType.TEXT_ANALYSIS_RESULT, InferenceResponseDto>
  | wsData<
      WsMessageType.DOCUMENT_TRANSLATION_RESULT,
      GetDocumentTranslationResponseDto
    >
  | wsData<
      WsMessageType.TEXT_GENERATION_INSIGHT_QUERY_KB,
      ArticleInsightQueryKbDto
    >
  | wsData<
      WsMessageType.TEXT_GENERATION_INSIGHT_CITATION,
      ArticleInsightCitationDto
    >
  | wsData<
      WsMessageType.TEXT_GENERATION_INSIGHT_OUTLINE,
      ArticleInsightOutlineDto
    >
  | wsData<
      WsMessageType.TEXT_GENERATION_INSIGHT_SECTION,
      ArticleInsightSectionDto
    >
  | wsData<WsMessageType.TEXT_GENERATION_INSIGHT, ChatStreamResponseDto>
  | wsData<WsMessageType.SECTION_GENERATION, ChatStreamResponseDto>
  | wsData<
      WsMessageType.SECTION_GENERATION_ON_CITATION,
      ArticleInsightCitationDto
    >
  | wsData<WsMessageType.SECTION_GENERATION_ON_FULL_CONTENT, SectionInsightDto>
  | wsData<WsMessageType.SECTION_GENERATION_ON_CONTENT, SectionInsightDto>;

const dispatchWsMessage = (message: wsResponse) => {
  const store = getStore();
  switch (message.type) {
    case WsMessageType.INFERENCE_RESULT:
      store.dispatch(correction.actions.updateGrammarList(message.payload));
      break;
    case WsMessageType.CHAT_RESULT:
      store.dispatch(chat.actions.updateChatResult(message.payload));
      break;
    case WsMessageType.TEXT_GENERATION_RESULT:
      store.dispatch(compose.actions.updateGeneratedText(message.payload));
      break;
    case WsMessageType.TEXT_ANALYSIS_RESULT:
      store.dispatch(
        writingStyle.actions.updateWritingStyleList(message.payload),
      );
      break;
    case WsMessageType.DOCUMENT_TRANSLATION_RESULT:
      store.dispatch(
        translation.actions.updateDocumentTranslationResult(message.payload),
      );
      if (message.payload.status === 'SUCCESS') {
        store.dispatch(
          app.actions.setGlobalMessage({
            message: `Document ${message.payload.name} translation successful`,
            status: 'success',
          }),
        );
      } else if (message.payload.status === 'ERROR') {
        app.actions.setGlobalMessage({
          message: `Document ${message.payload.name} translation failed: ${message.payload.errorMessage}`,
          status: 'error',
        });
      }
      break;
    case WsMessageType.TEXT_GENERATION_INSIGHT_QUERY_KB:
      store.dispatch(article.actions.updateQueryKb(message.payload));
      break;
    case WsMessageType.TEXT_GENERATION_INSIGHT_CITATION:
      store.dispatch(article.actions.updateCitation(message.payload));
      break;
    case WsMessageType.TEXT_GENERATION_INSIGHT_OUTLINE:
      store.dispatch(article.actions.updateOutline(message.payload));
      break;
    case WsMessageType.TEXT_GENERATION_INSIGHT_SECTION:
      store.dispatch(article.actions.updateSection(message.payload));
      break;
    case WsMessageType.TEXT_GENERATION_INSIGHT:
      store.dispatch(article.actions.updateGeneratedText(message.payload));
      break;
    case WsMessageType.SECTION_GENERATION:
      store.dispatch(article.actions.updateSectionGeneration(message.payload));
      break;
    case WsMessageType.SECTION_GENERATION_ON_CONTENT:
      store.dispatch(article.actions.updatePreviewSectionText(message.payload));
      break;
    case WsMessageType.SECTION_GENERATION_ON_CITATION:
      store.dispatch(article.actions.setSectionCitations(message.payload));
      break;
    case WsMessageType.SECTION_GENERATION_ON_FULL_CONTENT:
      store.dispatch(article.actions.updateFullContentSection(message.payload));
      break;
  }
};

const onMessage = (message: { data: string }) => {
  dispatchWsMessage(JSON.parse(message.data));
  getStore().dispatch(webSocket.actions.onMessage(JSON.parse(message.data)));
};

export const SocketHandler = () => {
  const [connection, setConnection, connectionRef] = useStateAndRef<
    WebSocket | undefined | null
  >();
  const dispatch = useDispatch<any>();
  const auth = useSelector((state: RootState) => state.auth);

  const setupConnection = useCallback(async () => {
    if (!auth.loggedIn) {
      return;
    }
    try {
      const wsConnection = await getSocketConnection();
      dispatch(webSocket.actions.onMessage());
      setConnection(wsConnection);
    } catch (error) {
      console.error(error);
      setConnection(null);
    }
  }, [setConnection, auth.loggedIn]);

  const onDisconnect = useCallback(() => {
    console.log('ws disconnected');
    if (connection?.readyState === WebSocket.OPEN) {
      connection.close();
    }
    setConnection(undefined);
  }, [setConnection, connection]);

  useEffect(() => {
    window.addEventListener('offline', onDisconnect);
    return () => window.removeEventListener('offline', onDisconnect);
  }, []);

  useEffect(() => {
    if (!connection) {
      setupConnection();
    } else {
      connection.addEventListener('close', onDisconnect);
      connection.addEventListener('message', onMessage);
    }

    return () => {
      connection?.removeEventListener('close', onDisconnect);
      connection?.removeEventListener('message', onMessage);
      connection?.close();
    };
  }, [connection]);

  useEffect(() => {
    dispatch(
      webSocket.actions.changeConnected(
        connection?.readyState === WebSocket.OPEN,
      ),
    );
  }, [connection?.readyState]);

  // send and detect heartbeat
  useEffect(() => {
    const interval = setInterval(() => {
      const con = connectionRef.current;
      if (con === undefined) {
        console.log('Waiting for initiation of ws');
        return;
      }
      if (con === null) {
        console.log('No ws connection, resetting...');
        onDisconnect();
        return;
      }
      const { lastMessageAt } = getStore().getState().webSocket;
      const messageGap = (Date.now() - lastMessageAt) / 1000;
      if (messageGap > 20) {
        // reconnect ws if heartbeat silence for over 20sec
        onDisconnect();
      }
      if (messageGap > 10) {
        con.send(JSON.stringify({ action: 'heartbeat' }));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <></>;
};
