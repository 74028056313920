/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ArticleInsightRequestDto,
  InsightApi,
  ArticleInsightQueryKbDto,
  ArticleInsightCitationDto,
  ArticleInsightOutlineDto,
  ArticleInsightSectionDto,
  CitationItemDto,
  ArticleApi,
  KnowledgeBaseConnectorDto,
  SectionInsightRequestDto,
  SectionInsightDto,
  ConnectorFilterDtoTypeEnum,
} from '@ink-ai/insight-service-sdk';
import { RootState } from '.';
import { getApi } from '../common/requestHelper';
import { ChatStreamResponseDto } from './chat';
import { StepData } from '../common/components/ai-assistant/Compose/StatusDisplay';
import { app } from './app';
import { downloadFile, isOffice } from '../common/utils';
import { SelectedConnectors } from './connector-filter';
import { marked } from 'marked';

export interface ExtCitationItemDto extends CitationItemDto {
  checked?: boolean;
}

const initialState = {
  inputtedText: '',
  citationsPadding: 0,
  textEdited: false,
  isGenerating: false,
  taskId: '',
  citations: [] as ExtCitationItemDto[],
  currentCitationId: -1,
  currentStep: -1,
  steps: [] as StepData[],
  selectedConnectors: [] as string[],
  inputValue: '',
  isEditorLocked: false,
  articleId: '',
  articleTitle: '',
  showSectionDialog: false,
  previewSectionText: '',
  previewCitations: [] as ExtCitationItemDto[],
  showPreviewHtml: false,
  previewCitationFilterText: '',
};

export type ArticleState = typeof initialState;

const appendCitations = (
  state: ArticleState,
  citations: ExtCitationItemDto[],
) => {
  citations.forEach((citation) => {
    const newCitationIndex = state.citationsPadding + citation.id;
    state.citations.push({
      ...citation,
      id: newCitationIndex,
    });
  });
};

const insertBufferToTinyMCE = (state: ArticleState, buffer: string) => {
  let tweakedBuffer = buffer.replaceAll(/\[\[citation:(\d+)\]\]/g, (_, p1) => {
    const paddedCitationId = parseInt(p1, 10) + state.citationsPadding;
    return `<a href="" data-citation-id="${paddedCitationId}">[${paddedCitationId}]</a>`;
  });

  tweakedBuffer += '<br/>';

  window.tinymce.activeEditor?.insertContent(tweakedBuffer, {
    format: 'raw',
  });
};

const insertBufferToWordEditor = (state: ArticleState, buffer: string) => {
  const tweakedBuffer = buffer.replaceAll(
    /\[\[citation:(\d+)\]\]/g,
    (_, p1) => {
      const paddedCitationId = parseInt(p1, 10) + state.citationsPadding;
      return `<a href="" data-citation-id="${paddedCitationId}">[${paddedCitationId}]</a>`;
    },
  );

  Word.run(async (context) => {
    const doc = context.document;
    const originalRange = doc.getSelection();

    const insertedRange = originalRange.insertHtml(
      `${tweakedBuffer}`,
      Word.InsertLocation.after,
    );
    await context.sync();

    insertedRange.load('end');
    await context.sync();
    insertedRange.select();
    await context.sync();
    const newRange = insertedRange.insertText(`\n`, Word.InsertLocation.after); // \n is a magic string to avoid the next paragraph's style to override the current one
    await context.sync();
    newRange.load('end');
    await context.sync();

    // Move the cursor to the end of the inserted text
    newRange.select();
    await context.sync();
  });
};

const insertBufferToEditor = (state: ArticleState, buffer: string) => {
  if (isOffice()) {
    insertBufferToWordEditor(state, buffer);
  } else if (window.tinymce.activeEditor) {
    insertBufferToTinyMCE(state, buffer);
  }
};

export const startArticleGeneration = createAsyncThunk(
  'insight/startArticleGeneration',
  async (
    params: {
      text: string;
      connectors: KnowledgeBaseConnectorDto[];
    },
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const insightApi = await getApi(InsightApi);

    const text = params?.text ?? state.article.inputtedText;
    const instanceId = state.auth.instanceId;
    const connectors = params?.connectors;

    if (!text || !instanceId) {
      throw new Error('Text and InstanceId are required.');
    }

    const requestBody: ArticleInsightRequestDto = {
      text,
      instanceId,
      connectors,
    };

    dispatch(article.actions.initSteps());

    const res = await insightApi.generateArticle(requestBody);
    dispatch(
      article.actions.setCitationsPadding(state.article.citations.length),
    );
    return res.data.id;
  },
);

export const transformConnectorData = (
  inputs: SelectedConnectors[],
): KnowledgeBaseConnectorDto[] => {
  return inputs
    .filter((input) => input.connector) // remove connector is null
    .map((input) => {
      const { connector, selectedFilters, maxResults } = input;
      const filters = selectedFilters.map((filter: any) => {
        let value = filter.selectedValue?.value || filter.stringValue;
        if (filter.field.type === ConnectorFilterDtoTypeEnum.Datetime) {
          value = (filter.dateValue / 1000).toString();
        }
        if (filter.field.type === ConnectorFilterDtoTypeEnum.DateRange) {
          value = JSON.stringify([
            filter.dateRangeStartTime / 1000,
            filter.dateRangeEndTime / 1000,
          ]);
        }
        return {
          field_name: filter.field.field_name,
          value: value,
          operator: filter.operator.value,
        };
      });

      return {
        lambdaName: connector.lambdaName,
        filters,
        maxResults: parseInt(maxResults.toString(), 10),
      };
    });
};

export const startInstructionContentGeneration = createAsyncThunk(
  'insight/startInstructionContentGeneration',
  async (
    params: {
      isRegenerate: boolean;
    },
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const insightApi = await getApi(InsightApi);
    // Reset article state when regenerating
    if (params.isRegenerate) {
      dispatch(article.actions.resetPreviewState());
    }

    let text = state.composeInstruction?.userInstructionText;
    const instanceId = state.auth.instanceId;
    if (!text || !instanceId) {
      throw new Error('Text and InstanceId are required.');
    }
    if (state.composeInstruction.currentTemplate) {
      // replace {} with actual values
      text = text.replace(/\{([^{}]+)\}/g, (match, key) => {
        return key in state.composeInstruction.userInstructionInputObj
          ? state.composeInstruction?.userInstructionInputObj?.[key]
          : match ?? '';
      });
    }
    const connectorFilters = state.connectorFilter?.selectedConnectors
      ? [...transformConnectorData(state.connectorFilter.selectedConnectors)]
      : [];
    const extraCheckedCitations = state.article.previewCitations.filter(
      (c) => c.checked,
    );
    const extraFileList = state.reference?.fileList?.filter(
      (file) => file.checked,
    );

    // reference file list
    const requestBody: SectionInsightRequestDto = {
      text: text,
      keywords: params.isRegenerate
        ? {}
        : state?.composeInstruction?.userInstructionInputObj ?? {},
      instanceId: instanceId,
      instructionId: state.composeInstruction?.currentTemplate?.id ?? '',
      connectors: params.isRegenerate ? [] : connectorFilters,
      extraCitations: params.isRegenerate ? extraCheckedCitations : [],
      referenceDocuments: params.isRegenerate
        ? []
        : extraFileList?.map((file) => file.id),
    };

    dispatch(article.actions.initSteps());

    const res = await insightApi.generateSection(requestBody);
    dispatch(
      article.actions.setCitationsPadding(state.article.citations.length),
    );
    return res.data.id;
  },
);

export const saveArticleContent = createAsyncThunk(
  'insight/saveArticleContent',
  async (content: string, { getState, dispatch }) => {
    const state = getState() as RootState;
    const articleApi = await getApi(ArticleApi);
    dispatch(article.actions.setEditorLocked(true));
    try {
      if (state.article.articleId === '') {
        const res = await articleApi.createArticle({
          content,
          title: state.article.articleTitle,
          citations: state.article.citations,
          tags: [],
        });
        dispatch(article.actions.setArticleId(res.data.id));
      } else {
        await articleApi.updateArticle(state.article.articleId, {
          title: state.article.articleTitle,
          content,
          citations: state.article.citations,
          tags: [],
        });
      }
      dispatch(
        app.actions.setGlobalMessage({
          message: 'Article saved successfully.',
          status: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(
        app.actions.setGlobalMessage({
          message: error.message || 'Failed to save article.',
          status: 'error',
        }),
      );
    }
  },
);

export const downloadArticle = async (articleId: string) => {
  const articleApi = await getApi(ArticleApi);
  const response = await articleApi.getOfficeWordFormat(articleId);
  const { data } = response;

  downloadFile(
    new Blob([Buffer.from(data.file)], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    }),
    data.name,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  );
};

export const saveAndDownloadArticle = createAsyncThunk(
  'article/saveAndDownloadArticle',
  async (content: string, { getState, dispatch }) => {
    const state = getState() as RootState;
    const articleApi = await getApi(ArticleApi);

    dispatch(article.actions.setEditorLocked(true));
    try {
      let articleId = state.article.articleId;

      if (!articleId) {
        const saveResponse = await articleApi.createArticle({
          content,
          title: state.article.articleTitle,
          citations: state.article.citations,
          tags: [],
        });
        articleId = saveResponse.data.id;
        dispatch(article.actions.setArticleId(articleId));
      } else {
        await articleApi.updateArticle(articleId, {
          title: state.article.articleTitle,
          content,
          citations: state.article.citations,
          tags: [],
        });
      }

      await downloadArticle(articleId);
    } catch (error: any) {
      dispatch(
        app.actions.setGlobalMessage({
          message: error.message || 'Failed to save and download article.',
          status: 'error',
        }),
      );
    } finally {
      dispatch(article.actions.setEditorLocked(false));
    }
  },
);

export const article = createSlice({
  name: 'article',
  initialState,
  reducers: {
    setFullState(_state, { payload }: PayloadAction<ArticleState>) {
      console.log('setArticleState', payload);
      return payload;
    },
    setArticleTitle: (state, { payload }: PayloadAction<string>) => {
      state.articleTitle = payload;
    },
    updateCurrentStep: (state, { payload }: PayloadAction<number>) => {
      state.currentStep = payload;
    },
    setEditorLocked: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditorLocked = payload;
    },
    setArticleId: (state, { payload }: PayloadAction<string>) => {
      state.articleId = payload;
      state.isEditorLocked = false;
    },
    initSteps: (state) => {
      state.steps = [];
      state.steps.push({
        label: 'Connect to Knowledge Base',
        optionalText: '',
      });
      state.currentStep = 0;
    },
    addStep: (state, { payload }: PayloadAction<StepData>) => {
      state.steps.push(payload);
    },
    setCitationsPadding: (state, { payload }: PayloadAction<number>) => {
      state.citationsPadding = payload;
    },
    updateSelectedConnectors: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedConnectors = payload;
    },
    updateInputValue: (state, { payload }: PayloadAction<string>) => {
      state.inputValue = payload;
    },
    updateGeneratedText: (
      state,
      { payload }: PayloadAction<ChatStreamResponseDto>,
    ) => {
      if (payload.id !== state.taskId) {
        console.warn('Id mismatch, ignore text generation result');
        return;
      }
      if (payload.isFinal) {
        state.currentStep = state.steps.length + 1;
        state.isGenerating = false;
        state.isEditorLocked = false;
      }
    },
    updateQueryKb: (
      state,
      { payload }: PayloadAction<ArticleInsightQueryKbDto>,
    ) => {
      if (payload.id !== state.taskId) {
        console.warn('Id mismatch, ignore query KB result');
        return;
      }
      state.steps[0].optionalText = `Connected to ${payload.payload.join(
        '\n',
      )}`;
      state.steps.push({ label: 'Find Citations', optionalText: '' });
      state.currentStep = state.steps.length - 1;
    },

    setCitations: (state, { payload }: PayloadAction<ExtCitationItemDto[]>) => {
      state.citations = payload;
    },

    updateCitation: (
      state,
      { payload }: PayloadAction<ArticleInsightCitationDto>,
    ) => {
      if (payload.id !== state.taskId) {
        console.warn('Id mismatch, ignore citation result');
        return;
      }
      appendCitations(state, payload.citations);
      const lastStep = state.steps[state.steps.length - 1];
      if (lastStep.label === 'Generate Outline') {
        // More citations, append to citation count
        state.steps[
          state.steps.length - 2
        ].optionalText = `${state.citations.length} citations found`;
      } else {
        // first citation, add outline step
        state.steps[
          state.steps.length - 1
        ].optionalText = `${state.citations.length} citations found`;
        state.steps.push({ label: 'Generate Outline', optionalText: '' });
      }
      state.currentStep = state.steps.length - 1;
    },

    updateOutline: (
      state,
      { payload }: PayloadAction<ArticleInsightOutlineDto>,
    ) => {
      if (payload.id !== state.taskId) {
        console.warn('Id mismatch, ignore outline result');
        return;
      }
      state.steps[
        state.steps.length - 1
      ].optionalText = `${payload.outline.join('\n')}`;
      state.steps.push({ label: 'Write Sections', optionalText: '' });
      state.currentStep = state.steps.length - 1;
    },

    updateSection: (
      state,
      { payload }: PayloadAction<ArticleInsightSectionDto>,
    ) => {
      if (payload.id !== state.taskId) {
        console.warn('Id mismatch, ignore section result');
        return;
      }
      insertBufferToEditor(state, payload.section);
    },
    inputText: (state, { payload }: PayloadAction<string>) => {
      state.inputtedText = payload;
      state.textEdited = payload !== '';
    },
    stopGenerating: (state) => {
      state.isGenerating = false;
      state.isEditorLocked = false;
      state.taskId = '';
    },
    clearText: (state) => {
      state.inputtedText = '';
      state.textEdited = false;
    },
    clearAll: () => {
      return initialState;
    },
    syncSelectedText: (state, { payload }: PayloadAction<string>) => {
      state.inputtedText = payload;
      state.textEdited = false;
    },
    setCurrentCitationId: (state, { payload }: PayloadAction<number>) => {
      state.currentCitationId = payload;
    },
    showSectionDialog: (
      state,
      {
        payload,
      }: PayloadAction<{
        show: boolean;
      }>,
    ) => {
      state.showSectionDialog = payload.show;
      state.showPreviewHtml = false;
      if (!payload.show) {
        state.previewSectionText = '';
        state.previewCitations = [];
      }
    },
    updatePreviewSectionText: (
      state,
      { payload }: PayloadAction<SectionInsightDto>,
    ) => {
      state.previewSectionText += payload.content;
    },
    updateFullContentSection: (
      state,
      { payload }: PayloadAction<SectionInsightDto>,
    ) => {
      // convert to markdown to html
      const htmlContent = marked(payload.content) as string;
      // replace citation
      const htmlContentWithCitations = htmlContent.replaceAll(
        /\[\[citation:(\d+)\]\]/g,
        (_, p1) => {
          const paddedCitationId = parseInt(p1, 10) + state.citationsPadding;
          return `<a style="color:blue" href="#" data-citation-id="${paddedCitationId}">[${paddedCitationId}]</a>`;
        },
      );
      state.showPreviewHtml = true;
      state.previewSectionText = htmlContentWithCitations;
    },
    updateSectionGeneration: (
      state,
      { payload }: PayloadAction<ChatStreamResponseDto>,
    ) => {
      if (payload.id !== state.taskId) {
        console.warn('Id mismatch, ignore text generation result');
        return;
      }
      if (payload.isFinal) {
        state.currentStep = state.steps.length + 1;
        state.isGenerating = false;
        state.isEditorLocked = false;
      }
    },
    setSectionCitations: (
      state,
      { payload }: PayloadAction<ArticleInsightCitationDto>,
    ) => {
      const citation = payload.citations[0];
      const newCitationId = state.citationsPadding + citation.id;

      const newCitation = {
        ...citation,
        id: newCitationId,
        checked: true,
      };

      // Find the correct index to insert the new citation
      const insertIndex = state.previewCitations.findIndex(
        (existingCitation) => existingCitation.id > newCitationId,
      );

      if (insertIndex === -1) {
        // If no larger ID is found, append the citation to the end
        state.previewCitations.push(newCitation);
      } else {
        // Otherwise, insert the citation at the determined position
        state.previewCitations.splice(insertIndex, 0, newCitation);
      }
    },
    changePreviewCitationChecked: (
      state,
      { payload }: PayloadAction<{ id: number; checked: boolean }>,
    ) => {
      const citation = state.previewCitations.find((c) => c.id === payload.id);
      if (citation) {
        citation.checked = payload.checked;
      }
    },
    insertSectionToDocument: (state) => {
      const insertHtml = state.previewSectionText;
      insertBufferToEditor(state, insertHtml);
      state.showSectionDialog = false;
      state.previewSectionText = '';
      state.citations = [...state.citations, ...state.previewCitations];
      state.previewCitations = [];
    },
    resetPreviewState: (state) => {
      state.previewSectionText = '';
      state.previewCitations = [];
      state.showPreviewHtml = false;
      // reset current citation id
      state.currentCitationId = -1;
    },
    changePreviewCitationFilterText: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.previewCitationFilterText = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(startArticleGeneration.pending, (state) => {
      state.isGenerating = true;
      state.isEditorLocked = true;
    });
    builder.addCase(startArticleGeneration.fulfilled, (state, { payload }) => {
      state.taskId = payload;
    });
    builder.addCase(startArticleGeneration.rejected, (state) => {
      state.isGenerating = false;
      state.isEditorLocked = false;
    });
    builder.addCase(startInstructionContentGeneration.pending, (state) => {
      state.isGenerating = true;
      state.isEditorLocked = true;
    });
    builder.addCase(
      startInstructionContentGeneration.fulfilled,
      (state, { payload }) => {
        state.taskId = payload;
        state.showSectionDialog = true;
      },
    );
    builder.addCase(startInstructionContentGeneration.rejected, (state) => {
      state.isGenerating = false;
      state.isEditorLocked = false;
    });
    builder.addCase(saveArticleContent.fulfilled, (state) => {
      state.isEditorLocked = false;
    });
    builder.addCase(saveArticleContent.rejected, (state) => {
      state.isEditorLocked = false;
    });
  },
});
