/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useRef, useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import {
  article,
  saveAndDownloadArticle,
  saveArticleContent,
} from '../../../reducers/article';
import debounce from 'lodash/debounce';
import { ASSISTANT_TABS } from '@ink-ai/portal/reducers/ai-assistant';
import { aiAssistant } from '../../../reducers/ai-assistant';

export interface TinyMceEditorProps {
  content?: string;
}

export const TinyMceEditor = ({ content = '' }: TinyMceEditorProps) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const dispatch = useDispatch<any>();
  const isEditorLocked = useSelector(
    (state: RootState) => state.article.isEditorLocked,
  );

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(content, {
        format: 'raw',
      });
    }
  }, [content]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setProgressState(isEditorLocked);
    }
  }, [isEditorLocked]);

  const updateArticleTitle = useCallback(
    debounce(() => {
      const content = editorRef.current?.getContent({ format: 'text' });
      let firstLine = '';
      if (content) {
        firstLine = content.split('\n')[0].trim();
      }

      const maxLength = 60;

      if (firstLine.length > maxLength) {
        firstLine = firstLine.substring(0, maxLength);
      }
      dispatch(article.actions.setArticleTitle(firstLine || 'Untitled'));
    }, 500),
    [dispatch],
  );

  const handleEditorChange = () => {
    updateArticleTitle();
  };

  const handleExportClick = async () => {
    const content = editorRef.current?.getContent();
    if (content) {
      dispatch(saveAndDownloadArticle(content));
    }
  };

  const handleSaveClick = async () => {
    const content = editorRef.current?.getContent();
    if (content) {
      dispatch(saveArticleContent(content));
    }
  };

  return (
    <>
      <Editor
        onClick={(e) => {
          const citationId = e.target?.getAttribute('data-citation-id');
          if (citationId) {
            dispatch(
              aiAssistant.actions.setCurrentTab(ASSISTANT_TABS.CITATION),
            );
          }
          dispatch(
            article.actions.setCurrentCitationId(parseInt(citationId, 10)),
          );
        }}
        id="text-editor"
        tinymceScriptSrc="/js/tinymce/tinymce.min.js"
        toolbar={
          'saveButton undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | exportButton'
        }
        onInit={(_evt, editor) => {
          editorRef.current = editor;
        }}
        onEditorChange={handleEditorChange}
        init={{
          height: 'calc(100vh - 160px)',
          content_css: '/css/tinymce.css',
          init_instance_callback: (editor) => {
            editor.setContent(content, {
              format: 'raw',
            });
            editor.setProgressState(isEditorLocked);
          },
          setup: (editor) => {
            editor.ui.registry.addButton('exportButton', {
              text: 'Export to Word',
              icon: 'export',
              onAction: () => {
                handleExportClick();
              },
            });
            editor.ui.registry.addButton('saveButton', {
              text: 'Save',
              icon: 'save',
              onAction: () => {
                handleSaveClick();
              },
            });
          },
        }}
      />
    </>
  );
};
