/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useState } from 'react';
import {
  Box,
  Container,
  Paper,
  TextField,
  Typography,
  Breadcrumbs,
  Tabs,
  Tab,
  Button,
  Alert,
  Link,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigationListener } from '../../components/hooks/useNavigationListener';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@ink-ai/portal/reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
  connectorActions,
  importConnector,
} from '@ink-ai/portal/reducers/connector';

export const ImportConnector: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const connector = useSelector((state: RootState) => state.connector);
  const [tabValue, setTabValue] = useState(1);
  const [lambdaName, setLambdaName] = useState('');

  useNavigationListener();

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLambdaName(e.target.value);
  };

  const handleCancel = () => {
    navigate('/settings/connectors');
  };

  const handleImport = () => {
    dispatch(connectorActions.setLoading(true));
    dispatch(importConnector(lambdaName));
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="textPrimary">Settings</Typography>
        <Typography color="textPrimary">Connectors</Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ textAlign: 'left', mb: 2 }}>
          Import Connector
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="connector tabs"
        >
          <Tab label="Search Engine" />
          <Tab label="Knowledge base" />
        </Tabs>
        {tabValue === 0 && (
          <Box sx={{ mt: 2, textAlign: 'left' }}>
            <Alert
              severity="info"
              sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'right',
                width: '60%',
                border: '1px solid #2196f3',
                backgroundColor: '#fff',
                paddingRight: '40px',
              }}
            >
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Connect with search engine
                </Typography>
                <Typography variant="body2">
                  A search engine connector allows you to query information from
                  public or private search engines. You can configure the
                  connector to integrate with various APIs to fetch search
                  results.
                </Typography>
              </Box>
            </Alert>
            <TextField
              label="Search engine connector Lambda Name"
              variant="outlined"
              value={lambdaName}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: '60%', mt: 1 }}
            />
            <Box
              sx={{
                mt: 2,
                textAlign: 'left',
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleImport}
                disabled={!lambdaName}
                sx={{ width: '99px' }}
                loading={connector.loading}
              >
                Import
              </LoadingButton>
              <Button onClick={handleCancel} sx={{ ml: 1, width: '99px' }}>
                Cancel
              </Button>
            </Box>
          </Box>
        )}
        {tabValue === 1 && (
          <Box sx={{ mt: 2, textAlign: 'left' }}>
            <Alert
              severity="info"
              sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'right',
                width: '60%',
                border: '1px solid #2196f3',
                backgroundColor: '#fff',
                paddingRight: '40px',
              }}
            >
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Connect with knowledge base
                </Typography>
                <Typography variant="body2">
                  A knowledge base refers to a system, which can query and
                  return relevant information using APIs. A valid knowledge base
                  could be a RAG (Retrieval-Augmented Generation) system with
                  private datasets.
                </Typography>
              </Box>
            </Alert>
            <TextField
              label="Knowledge base connector Lambda Name"
              variant="outlined"
              value={lambdaName}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: '60%', mt: 1 }}
            />
            <Box
              sx={{
                mt: 2,
                textAlign: 'left',
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleImport}
                disabled={!lambdaName}
                sx={{ width: '99px' }}
                loading={connector.loading}
              >
                Import
              </LoadingButton>
              <Button onClick={handleCancel} sx={{ ml: 1, width: '99px' }}>
                Cancel
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Container>
  );
};
