/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useMemo, useState } from 'react';
import { ImportConnectorResponseDto } from 'packages/insight-service-sdk/dist';
import { formatDate } from '@ink-ai/portal/common/utils';
import { PaginatedTable } from '../../../common/components/paginated-table/PaginatedTable';
import { EllipsisCell } from '../../../common/components/paginated-table/EllipsisCell';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import { listConnectors } from '@ink-ai/portal/reducers/connector';

interface ConnectorTableProps {
  selectedConnectors: string[];
  searchTerm: string;
  page: number;
  onPageChange: (page: number) => void;
  onSelectedConnectorsChange: (selectedConnectors: string[]) => void;
}

export const ConnectorTable: React.FC<ConnectorTableProps> = ({
  selectedConnectors,
  onSelectedConnectorsChange,
  searchTerm,
  page,
  onPageChange,
}) => {
  const dispatch = useDispatch<any>();
  const connector = useSelector((state: RootState) => state.connector);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(listConnectors());
  }, [dispatch]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const currentPageConnectors = filteredConnectors.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      );
      const newSelecteds = currentPageConnectors.map((g) => g.uuid);
      onSelectedConnectorsChange(newSelecteds);
    } else {
      onSelectedConnectorsChange([]);
    }
  };

  const handleClick = (uuid: string) => {
    const selectedIndex = selectedConnectors.indexOf(uuid);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedConnectors, uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedConnectors.slice(1));
    } else if (selectedIndex === selectedConnectors.length - 1) {
      newSelected = newSelected.concat(selectedConnectors.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedConnectors.slice(0, selectedIndex),
        selectedConnectors.slice(selectedIndex + 1),
      );
    }

    onSelectedConnectorsChange(newSelected);
  };

  const handlePageChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    newPage: number,
  ) => {
    onPageChange(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageChange(0);
  };

  const filteredConnectors = useMemo(
    () =>
      (connector.connectors || []).filter((g) =>
        g.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [connector.connectors, searchTerm],
  );

  const columns = [
    {
      id: 'name',
      label: 'Name',
      render: (connector: ImportConnectorResponseDto) => (
        <EllipsisCell content={connector.name} tooltip={connector.name} />
      ),
      width: 150,
    },
    {
      id: 'type',
      label: 'Type',
      render: (connector: ImportConnectorResponseDto) => connector.type,
      width: 150,
    },
    {
      id: 'desc',
      label: 'Description',
      render: (connector: ImportConnectorResponseDto) => (
        <EllipsisCell content={connector.description || '-'} />
      ),
      width: 150,
    },
    {
      id: 'lambdaName',
      label: 'Lambda Name',
      render: (connector: ImportConnectorResponseDto) => (
        <EllipsisCell
          content={connector.lambdaName}
          tooltip={connector.lambdaName}
        />
      ),
      width: 150,
    },
    {
      id: 'createdAt',
      label: 'Created at',
      render: (connector: ImportConnectorResponseDto) =>
        formatDate(connector.createdAt),
      width: 150,
    },
  ];

  return (
    <PaginatedTable
      dummyPagination
      columns={columns}
      data={filteredConnectors}
      selectedItems={selectedConnectors}
      page={page}
      rowsPerPage={rowsPerPage}
      loading={connector.loading}
      getItemId={(connector) => connector.uuid}
      onSelectAllClick={handleSelectAllClick}
      onItemClick={handleClick}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};
