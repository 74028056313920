/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useRef, useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Menu,
  MenuItem,
  Divider,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import { GlossaryCollectionTable } from '../components/glossary/GlossaryCollectionTable';
import {
  glossaryActions,
  exportGlossaryItemsToCsv,
  exportGlossaryItemsToJSON,
  deleteGlossaryCollections,
  handleDownload,
  listGlossaryCollections,
} from '../../reducers/glossary';
import {
  GlossaryCollectionDto,
  GlossaryCollectionDtoStatusEnum,
  GlossaryCollectionDtoTypeEnum,
} from '@ink-ai/insight-service-sdk';
import { RootState } from '@ink-ai/portal/reducers';
import { LoadingButton } from '@mui/lab';

export const Glossary: React.FC = () => {
  const dispatch = useDispatch<any>();
  const glossary = useSelector((state: RootState) => state.glossary);
  const navigate = useNavigate();
  const actionsButtonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGlossaries, setSelectedGlossaries] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [menuLoading, setMenuLoading] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(glossaryActions.setLoading(true));
    handleMenuClose();
    dispatch(deleteGlossaryCollections(selectedGlossaries));
    setSelectedGlossaries([]);
  };

  const handleExport = () => {
    handleMenuClose();
    if (selectedGlossaries.length === 1) {
      dispatch(exportGlossaryItemsToJSON(selectedGlossaries[0]));
    }
  };

  const handleExportCsv = async () => {
    setMenuLoading(true);

    if (selectedGlossaries.length === 1) {
      const selectedGlossary = glossary.glossaries.find(
        (g) => g.uuid === selectedGlossaries[0],
      );
      try {
        if (selectedGlossary?.type === GlossaryCollectionDtoTypeEnum.External) {
          await handleDownload(selectedGlossaries[0]);
        } else {
          await dispatch(exportGlossaryItemsToCsv(selectedGlossaries[0]));
        }
      } catch (error) {
        console.error('Error exporting glossary:', error);
      } finally {
        setMenuLoading(false);
      }
    } else {
      setMenuLoading(false);
    }
    handleMenuClose();
  };

  const handleUpdate = () => {
    if (selectedGlossaries.length === 1) {
      const selectedGlossary = glossary.glossaries.find(
        (g) => g.uuid === selectedGlossaries[0],
      );
      if (selectedGlossary) {
        navigate('/glossary/import', {
          state: {
            glossaryUuid: selectedGlossary.uuid,
            glossaryName: selectedGlossary.name,
            glossaryDescription: selectedGlossary.desc,
            glossaryType: selectedGlossary.type,
          },
        });
      }
    }
    handleMenuClose();
  };

  const handleNavigate = (glossary: GlossaryCollectionDto) => {
    dispatch(glossaryActions.setSelectedGlossary(glossary));
    navigate(`/glossary/${glossary.uuid}`);
  };

  const handleJsonExportDisabled = () => {
    if (selectedGlossaries.length !== 1) return true;
    const selectedGlossary = glossary.glossaries.find(
      (g) => g.uuid === selectedGlossaries[0],
    );
    return selectedGlossary?.type === GlossaryCollectionDtoTypeEnum.External;
  };

  const handleCsvExportDisabled = () => {
    return selectedGlossaries.length !== 1;
  };

  const handleUpdateDisabled = () => {
    if (selectedGlossaries.length !== 1) return true;
    const selectedGlossary = glossary.glossaries.find(
      (g) => g.uuid === selectedGlossaries[0],
    );
    return (
      selectedGlossary?.status === GlossaryCollectionDtoStatusEnum.Importing
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          color="inherit"
          onClick={() => navigate('/')}
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          Home
        </Link>
        <Typography color="textPrimary">Glossary</Typography>
      </Breadcrumbs>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" sx={{ textAlign: 'left', mb: 2 }}>
          Glossary
        </Typography>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleSearchChange}
          />
          <Box>
            <Button
              sx={{ mr: 1 }}
              aria-label="refresh"
              variant="outlined"
              onClick={() => dispatch(listGlossaryCollections())}
            >
              <RefreshIcon />
            </Button>
            <LoadingButton
              ref={actionsButtonRef}
              color="info"
              onClick={handleMenuOpen}
              endIcon={<ArrowDropDownIcon />}
              variant="outlined"
              disabled={selectedGlossaries.length === 0}
              loading={menuLoading}
            >
              Actions
            </LoadingButton>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
              onClick={() => navigate('/glossary/import')}
            >
              Import
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  style: {
                    minWidth: actionsButtonRef.current?.offsetWidth,
                  },
                },
              }}
            >
              <MenuItem
                onClick={handleExport}
                disabled={handleJsonExportDisabled() || menuLoading}
              >
                Export as JSON
              </MenuItem>
              <MenuItem
                onClick={handleExportCsv}
                disabled={handleCsvExportDisabled() || menuLoading}
              >
                Export as CSV
              </MenuItem>
              <MenuItem
                onClick={handleUpdate}
                disabled={handleUpdateDisabled() || menuLoading}
              >
                Update
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleDelete}
                disabled={selectedGlossaries.length === 0 || menuLoading}
              >
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <GlossaryCollectionTable
          selectedGlossaries={selectedGlossaries}
          onSelectedGlossariesChange={setSelectedGlossaries}
          searchTerm={searchTerm}
          onNavigate={handleNavigate}
          page={page}
          onPageChange={setPage}
        />
      </Paper>
    </Container>
  );
};
